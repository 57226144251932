import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ChevronDownIcon, ChevronUpIcon } from 'assets';
import { includes, xor } from 'lodash';
import { twMerge } from 'tailwind-merge';

import ExpandedRow from 'entities/Outgrower/components/OutgrowerListTable/ExpandedRow';
import { OutgrowerListResponse } from 'entities/Outgrower/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface OutgrowerListTableProps {
  outgrowers: Array<OutgrowerListResponse>;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
}

const OutgrowerListTable = ({
  outgrowers,
  onSort,
  sort
}: OutgrowerListTableProps) => {
  const navigate = useNavigate();

  const [expandedOutgrowers, setExpandedOutgrowers] = useState<Array<number>>(
    []
  );

  const tableIsEmpty = outgrowers.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0  ${tableIsEmpty && 'h-full'}`
        }}
      >
        <TableHead>
          <TableRow>
            <TableHeaderCell content="Name" className="min-w-[277px]" />
            <TableHeaderCell content="Location" className="min-w-[277px]" />
            <TableHeaderCell content="Active Farms" className="min-w-[250px]" />
            <SortableTableHeaderCell
              content="Active Contracts"
              onSort={onSort}
              sortBy={sort}
              sortName={'active_contracts'}
              className="min-w-[250px]"
            />
            <TableHeaderCell content="Details" className="w-[160px]" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState
              tableColumns={5}
              text={'No outgrowers available'}
            />
          )}
          {outgrowers.map((outgrower) => {
            const rowExpanded = includes(expandedOutgrowers, outgrower.id);

            return (
              <>
                <TableRow
                  hover
                  key={outgrower.id}
                  classes={{
                    root: twMerge(
                      'cursor-pointer',
                      `${rowExpanded ? 'bg-[#b3cbbd]/30' : 'odd:bg-white even:bg-[#b3cbbd]/10'}`
                    )
                  }}
                  onClick={() =>
                    navigate(
                      generatePath(URLS.OUTGROWER_PROFILE, {
                        outgrowerId: outgrower.id.toString()
                      })
                    )
                  }
                >
                  <TableBodyCell
                    content={outgrower.first_name + ' ' + outgrower.last_name}
                    className="font-medium"
                  />
                  <TableBodyCell
                    content={`${outgrower.town}, ${outgrower.country}`}
                  />
                  <TableBodyCell content={outgrower.farms.length || 0} />
                  <TableBodyCell content={outgrower.active_contracts || 0} />
                  <TableBodyCell
                    onClick={(event) => {
                      event.stopPropagation();
                      setExpandedOutgrowers(
                        xor(expandedOutgrowers, [outgrower.id])
                      );
                    }}
                    content={
                      <div className="flex cursor-pointer items-center justify-center gap-1">
                        {rowExpanded ? 'Hide' : 'View'}
                        {rowExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                      </div>
                    }
                  />
                </TableRow>
                {rowExpanded && <ExpandedRow outgrower={outgrower} />}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OutgrowerListTable;

import { useState } from 'react';

import { DockToLeft } from 'assets';

import Text from 'components/Text';

interface RightSidebarProps {
  rightSideBarItems: Array<React.ReactNode>;
}

const RightSidebar = ({ rightSideBarItems }: RightSidebarProps) => {
  const [opened, setOpened] = useState(true);

  const width = opened ? 'w-[270px]' : 'w-[53px]';

  return (
    <div
      className={`${width} flex flex-col items-start overflow-y-auto bg-[#B3CBBD] py-[25px] pl-4 pr-3`}
    >
      {!opened && (
        <DockToLeft
          className="mt-1 cursor-pointer"
          onClick={() => setOpened(true)}
        />
      )}

      {opened && (
        <>
          <div className="flex w-full items-center justify-between pb-7">
            <Text
              color="wet-green"
              className="text-xl font-medium leading-8 tracking-[0.15px]"
            >
              Details
            </Text>
            <DockToLeft
              className="cursor-pointer"
              onClick={() => setOpened(false)}
            />
          </div>
          <div className="flex w-full flex-col gap-4">{rightSideBarItems}</div>
        </>
      )}
    </div>
  );
};

export default RightSidebar;

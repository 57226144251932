import ClickableChip from 'components/ClickableChip';
import Skeleton from 'components/Skeleton';

const CropChipList = () => {
  return (
    <div className="shadow-[rgba(16, 24, 40, 0.05)] flex gap-2 rounded-xl border border-solid border-[#d4dae1] bg-white p-3 shadow-sm">
      <ClickableChip
        chipKey={''}
        icon={undefined}
        label={
          <div>
            <Skeleton />
          </div>
        }
        classes={{
          root: 'border border-solid border-[#062D29] px-4 py-[7px] m-0 text-sm'
        }}
        isClicked={false}
        onClick={() => {}}
      />
      <ClickableChip
        chipKey={''}
        icon={undefined}
        label={
          <div>
            <Skeleton />
          </div>
        }
        classes={{
          root: 'border border-solid border-[#062D29] px-4 py-[7px] m-0 text-sm'
        }}
        isClicked={false}
        onClick={() => {}}
      />
      <ClickableChip
        chipKey={''}
        icon={undefined}
        label={
          <div>
            <Skeleton />
          </div>
        }
        classes={{
          root: 'border border-solid border-[#062D29] px-4 py-[7px] m-0 text-sm'
        }}
        isClicked={false}
        onClick={() => {}}
      />
    </div>
  );
};

export default CropChipList;

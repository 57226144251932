import { useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { AddIcon, XIcon } from 'assets';

import ContractCloseDialog from 'entities/Contracts/components/ContractCloseDialog';
import ContractHarvestDialog from 'entities/Contracts/components/ContractHarvestDialog';
import {
  ContractListResponse,
  ContractsListFiltersProps
} from 'entities/Contracts/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

import { formatMoney, formatUnitAmount } from 'utils/numbers';
import { SwrMutator } from 'utils/sdk';
import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface ActiveContractsListTableProps {
  contracts: Array<ContractListResponse>;
  contractsRefetch: SwrMutator;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  filters: ContractsListFiltersProps;
  currency: string;
}

const ActiveContractsListTable = ({
  currency,
  contracts,
  contractsRefetch,
  onSort,
  sort,
  filters
}: ActiveContractsListTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tableIsEmpty = contracts.length === 0;
  const [contractCloseDialogOpened, setContractCloseDialogOpened] =
    useState(false);
  const [contractHarvestDialogOpened, setContractHarvestDialogOpened] =
    useState(false);
  const [contractId, setContractId] = useState<number | ''>('');

  const handleCloseDialogOpen = (contractId: number) => {
    setContractId(contractId);
    setContractCloseDialogOpened(true);
  };

  const handleCloseDialogClose = () => {
    setContractCloseDialogOpened(false);
    contractsRefetch();
  };

  const handleHarvestCloseDialogOpen = (contractId: number) => {
    setContractId(contractId);
    setContractHarvestDialogOpened(true);
  };

  const handleHarvestDialogClose = () => {
    setContractHarvestDialogOpened(false);
    contractsRefetch();
  };

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Farmer" className="min-w-[150px]" />
            <TableHeaderCell content="Crop" className="min-w-[150px]" />
            <SortableTableHeaderCell
              content="Land Size"
              className="min-w-[100px]"
              onSort={onSort}
              sortBy={sort}
              sortName={'land_size'}
            />
            <SortableTableHeaderCell
              content="Start Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'execution_start_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harverst_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
            />
            <SortableTableHeaderCell
              content="Actual Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'actual_harvest_amount'}
            />
            <SortableTableHeaderCell
              content={`Expected Farmgate Price (${currency})`}
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_farmgate_price'}
            />
            <TableHeaderCell content="Harvest" />
            <TableHeaderCell content="Close" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState
              tableColumns={11}
              text={'No contracts available'}
            />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    contractId: contract.id.toString(),
                    outgrowerId: contract.outgrower_id.toString()
                  }),
                  { state: { filters, sort, url: location.pathname } }
                )
              }
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell
                content={contract.crop}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell content={`${contract.land_size} ha`} />
              <TableBodyCell content={contract.execution_start_date} />
              <TableBodyCell content={contract.expected_harverst_date} />
              <TableBodyCell
                content={`${formatUnitAmount(contract.expected_harvest_amount)} t`}
              />
              <TableBodyCell
                content={`${formatUnitAmount(contract.actual_harvest_amount || '0')} t`}
              />
              <TableBodyCell
                content={`${formatMoney(contract.expected_farmgate_price)}`}
              />
              <TableBodyCell
                onClick={(event) => {
                  event.stopPropagation();
                  handleHarvestCloseDialogOpen(contract.id);
                }}
                content={<AddIcon className="mx-1.5" />}
                className="w-[150px]"
              />
              <TableBodyCell
                onClick={(event) => {
                  event.stopPropagation();
                  handleCloseDialogOpen(contract.id);
                }}
                content={<XIcon className="mx-1.5" />}
                className="w-[150px]"
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {contractCloseDialogOpened && contractId && (
        <ContractCloseDialog
          contractId={contractId}
          onClose={handleCloseDialogClose}
        />
      )}
      {contractHarvestDialogOpened && contractId && (
        <ContractHarvestDialog
          contractId={contractId}
          onClose={handleHarvestDialogClose}
        />
      )}
    </TableContainer>
  );
};

export default ActiveContractsListTable;

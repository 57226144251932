import ActiveContractDiaryStatistics from 'entities/Contracts/components/ContractDiaryStatistics/ActiveContractDiaryStatistics';
import ClosedContractDiaryStatistics from 'entities/Contracts/components/ContractDiaryStatistics/ClosedContractDiaryStatistics';
import ContractDiaryStatisticsLoading from 'entities/Contracts/components/ContractDiaryStatistics/ContractDiaryStatisticsLoading';
import UpcomingContractDiaryStatistics from 'entities/Contracts/components/ContractDiaryStatistics/UpcomingContractDiaryStatistics';
import { ContractDiaryProps } from 'entities/Contracts/sdk';

import { SwrMutator } from 'utils/sdk';

interface ContractDiaryStatisticsProps {
  contractDiary?: ContractDiaryProps;
  contractDiaryLoading: boolean;
  contractDiaryRefetch: SwrMutator;
}

const OutgrowerContractDiaryStatistics = ({
  contractDiary,
  contractDiaryLoading,
  contractDiaryRefetch
}: ContractDiaryStatisticsProps) => {
  return (
    <>
      {contractDiaryLoading && <ContractDiaryStatisticsLoading />}
      {!contractDiaryLoading && contractDiary?.status === 'active' && (
        <ActiveContractDiaryStatistics
          contractDiary={contractDiary}
          contractDiaryRefetch={contractDiaryRefetch}
        />
      )}
      {!contractDiaryLoading && contractDiary?.status === 'upcoming' && (
        <UpcomingContractDiaryStatistics contractDiary={contractDiary} />
      )}
      {!contractDiaryLoading && contractDiary?.status === 'closed' && (
        <ClosedContractDiaryStatistics contractDiary={contractDiary} />
      )}
    </>
  );
};

export default OutgrowerContractDiaryStatistics;

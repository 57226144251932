import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from 'assets';

import OutgrowerListFilters from 'entities/Outgrower/components/OutgrowerListFilters';
import OutgrowerListStatistics from 'entities/Outgrower/components/OutgrowerListStatistics';
import OutgrowerListTable from 'entities/Outgrower/components/OutgrowerListTable';
import {
  OutgrowerListFilterProps,
  useOutgrowersList
} from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import Card from 'components/Card';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

import { URLS } from 'config/urls';

const OutgrowersList = () => {
  const [filters, setFilters] = useState<OutgrowerListFilterProps>({});
  const [sort, setSort] = useState<ListSortProps>({
    sort: 'active_contracts',
    direction: 'desc'
  });

  const { setPageTitle, setActionButton } = useLayout();
  const navigate = useNavigate();

  const { data: outgrowers, isLoading: outgrowersLoading } = useOutgrowersList({
    filters,
    sort: buildSort(sort)
  });

  useEffect(() => {
    setPageTitle(<PageTitle text="Outgrowers"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  useEffect(() => {
    setActionButton(
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        color="wetGreen"
        onClick={() => navigate(URLS.OUTGROWER_CREATE)}
      >
        Add outgrower
      </Button>
    );

    return () => setActionButton(null);
  }, [setActionButton, navigate]);

  return (
    <div className="flex h-full flex-col gap-3 bg-[#F8F8F8] px-[26px] py-5">
      <OutgrowerListStatistics
        outgrowersLoading={outgrowersLoading}
        outgrowers={outgrowers}
      />
      <Card
        classes={{
          root: 'flex h-full flex-col gap-5 p-4 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
        }}
      >
        <OutgrowerListFilters onFilter={setFilters} />
        {outgrowersLoading && <ContentLoading />}
        {!outgrowersLoading && outgrowers && (
          <OutgrowerListTable
            outgrowers={outgrowers}
            onSort={setSort}
            sort={sort}
          />
        )}
      </Card>
    </div>
  );
};

export default OutgrowersList;

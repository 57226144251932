import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import {
  RAG_STATUS_COLOR_TO_ICON_MAPPER,
  RAG_STATUS_COLOR_TO_TITLE_MAPPER
} from 'entities/Contracts/constants';
import {
  ContractsDashboardListResponse,
  DashboardFiltersProps
} from 'entities/Dashboard/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';
import Tooltip from 'components/Tooltip';

import { formatMoney, formatSize, formatUnitAmount } from 'utils/numbers';
import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface DashboardListTableProps {
  contracts: Array<ContractsDashboardListResponse>;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  filters: DashboardFiltersProps;
  currency: string;
}

const DashboardListTable = ({
  filters,
  contracts,
  onSort,
  sort,
  currency
}: DashboardListTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tableIsEmpty = contracts.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Farmer" />
            <TableHeaderCell content="Farm" />
            <TableHeaderCell content="Lead Farmer" />
            <TableHeaderCell content="Location" />
            <SortableTableHeaderCell
              content="Size (ha)"
              onSort={onSort}
              sortBy={sort}
              sortName={'land_size'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Crop" />
            <TableHeaderCell content="Stage" className="w-[97px]" />
            <SortableTableHeaderCell
              content={`Exposure (${currency})`}
              onSort={onSort}
              sortBy={sort}
              sortName={'exposure'}
              className="w-[107px]"
            />
            <SortableTableHeaderCell
              content="Expected Harvest (t)"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
              className="w-[117px]"
            />
            <TableHeaderCell content="RAG Status" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState
              tableColumns={11}
              text={'No contracts available'}
            />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    outgrowerId: contract.outgrower_id.toString(),
                    contractId: contract.id.toString()
                  }),
                  { state: { filters, sort, url: location.pathname } }
                )
              }
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                className="font-medium"
              />
              <TableBodyCell
                content={contract.farm_name || contract.farm_number}
                className="font-medium"
              />
              <TableBodyCell
                content={`${contract.lead_outgrower_first_name || '-'} ${contract.lead_outgrower_last_name || '-'}`}
                className="font-medium"
              />
              <TableBodyCell
                content={`${contract.farm_town}, ${contract.farm_country}`}
              />
              <TableBodyCell content={formatSize(contract.land_size)} />
              <TableBodyCell content={contract.crop} />
              <TableBodyCell
                content={contract.current_stage || '-'}
                className="w-[97px]"
              />
              <TableBodyCell
                content={`${formatMoney(contract.exposure || '0')}`}
              />
              <TableBodyCell
                content={`${formatUnitAmount(contract.expected_harvest_amount)}`}
              />
              <TableBodyCell
                content={
                  <>
                    {contract.status === 'active' && (
                      <Tooltip
                        className="text-wet-green"
                        title={
                          RAG_STATUS_COLOR_TO_TITLE_MAPPER[
                            contract.rag_status_color
                          ]
                        }
                      >
                        <div>
                          {
                            RAG_STATUS_COLOR_TO_ICON_MAPPER[
                              contract.rag_status_color
                            ]
                          }
                        </div>
                      </Tooltip>
                    )}
                    {contract.status === 'upcoming' && <div>-</div>}
                  </>
                }
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardListTable;

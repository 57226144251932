import { useState } from 'react';

import { isNil, uniq, uniqBy } from 'lodash';

import {
  CONTRACT_STATUSES,
  RAG_STATUS_COLORS,
  RAG_STATUS_COLOR_TO_TITLE_MAPPER
} from 'entities/Contracts/constants';
import ClearAllFiltersButton from 'entities/Dashboard/components/ClearAllFiltersButton';
import DashboardFilter from 'entities/Dashboard/components/DashboardFilter';
import DashboardSearch from 'entities/Dashboard/components/DashboardSearch';
import LocationFilter from 'entities/Dashboard/components/LocationFilter';
import { DashboardFiltersProps as ContractsFilterProps } from 'entities/Dashboard/sdk';
import { ContractsDashboardListResponse } from 'entities/Dashboard/sdk';

interface DashboardFiltersProps {
  onFilter: (filters: ContractsFilterProps) => void;
  filters: ContractsFilterProps;
  contracts?: Array<ContractsDashboardListResponse>;
  resetSort: () => void;
}

const DashboardFilters = ({
  onFilter,
  contracts,
  filters,
  resetSort
}: DashboardFiltersProps) => {
  const [openedFilter, setOpenedFilter] = useState('');

  const contractsOutgrowers =
    contracts &&
    contracts.map((contract) => ({
      outgrowerId: contract.outgrower_id,
      outgrowerFirsName: contract.outgrower_first_name,
      outgrowerLastName: contract.outgrower_last_name
    }));

  const uniqueOutgrowers = uniqBy(contractsOutgrowers, 'outgrowerId');

  const outgrowerFilterOptions = uniqueOutgrowers.map((outgrower) => ({
    label: `${outgrower.outgrowerFirsName} ${outgrower.outgrowerLastName}`,
    value: outgrower.outgrowerId
  }));

  const contractsLeadFarmers =
    contracts &&
    contracts
      .filter((contract) => !isNil(contract.lead_outgrower_id))
      .map((contract) => ({
        leadOutgrowerId: contract.lead_outgrower_id,
        leadOutgrowerFirstName: contract.lead_outgrower_first_name,
        leadOutgrowerLastName: contract.lead_outgrower_last_name
      }));

  const uniqueLeadFarmers = uniqBy(contractsLeadFarmers, 'leadOutgrowerId');

  const leadFarmerFilterOptions = uniqueLeadFarmers.map((leadFarmer) => ({
    label: `${leadFarmer.leadOutgrowerFirstName} ${leadFarmer.leadOutgrowerLastName}`,
    value: leadFarmer.leadOutgrowerId
  }));

  const contractsLocation =
    contracts &&
    contracts.map(
      (contract) => `${contract.farm_town}, ${contract.farm_country} `
    );

  const uniqueLocations = uniq(contractsLocation);

  const locationFilterOptions = uniqueLocations.map((location) => ({
    label: location,
    value: location
  }));

  const statusFilterOptions = [
    {
      label: 'Active',
      value: CONTRACT_STATUSES.ACTIVE
    },
    {
      label: 'Upcoming',
      value: CONTRACT_STATUSES.UPCOMING
    }
  ];

  const ragStatusFilterOptions = [
    {
      label: RAG_STATUS_COLOR_TO_TITLE_MAPPER[RAG_STATUS_COLORS.GREEN],
      value: RAG_STATUS_COLORS.GREEN
    },
    {
      label: RAG_STATUS_COLOR_TO_TITLE_MAPPER[RAG_STATUS_COLORS.AMBER],
      value: RAG_STATUS_COLORS.AMBER
    },
    {
      label: RAG_STATUS_COLOR_TO_TITLE_MAPPER[RAG_STATUS_COLORS.RED],
      value: RAG_STATUS_COLORS.RED
    }
  ];

  return (
    <div className="flex justify-between">
      <div className="flex flex-wrap gap-2">
        <DashboardFilter
          filterName="status"
          filters={filters}
          label="Status"
          onFilter={onFilter}
          filterOptions={statusFilterOptions}
          isOpen={openedFilter === 'status'}
          openFilter={() => setOpenedFilter('status')}
          closeFilter={() => setOpenedFilter('')}
        />
        <DashboardFilter
          filterName="outgrower"
          filters={filters}
          label="Farmer"
          onFilter={onFilter}
          filterOptions={outgrowerFilterOptions}
          isOpen={openedFilter === 'outgrower'}
          openFilter={() => setOpenedFilter('outgrower')}
          closeFilter={() => setOpenedFilter('')}
        />
        <DashboardFilter
          filterName="lead_outgrower"
          filters={filters}
          label="Lead Farmer"
          onFilter={onFilter}
          filterOptions={leadFarmerFilterOptions}
          isOpen={openedFilter === 'lead_outgrower'}
          openFilter={() => setOpenedFilter('lead_outgrower')}
          closeFilter={() => setOpenedFilter('')}
        />
        <LocationFilter
          filters={filters}
          label="Location"
          onFilter={onFilter}
          filterOptions={locationFilterOptions}
          isOpen={openedFilter === 'location'}
          openFilter={() => setOpenedFilter('location')}
          closeFilter={() => setOpenedFilter('')}
        />
        <DashboardFilter
          filterName="rag_status_color"
          filters={filters}
          label="RAG status"
          onFilter={onFilter}
          filterOptions={ragStatusFilterOptions}
          isOpen={openedFilter === 'rag_status_color'}
          openFilter={() => setOpenedFilter('rag_status_color')}
          closeFilter={() => setOpenedFilter('')}
        />
        <ClearAllFiltersButton
          filters={filters}
          onFilter={onFilter}
          resetSort={resetSort}
        />
      </div>
      <DashboardSearch filters={filters} onFilter={onFilter} />
    </div>
  );
};

export default DashboardFilters;

import { OutgrowerContractsListResponse } from 'entities/Outgrower/sdk';

import Avatar from 'components/Avatar';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';

import { formatSize } from 'utils/numbers';

interface OutgrowerDetailsInContractsListProps {
  outgrower: OutgrowerContractsListResponse['outgrower'] | undefined;
  isLoading: boolean;
}

const OutgrowerDetailsInContractsList = ({
  outgrower,
  isLoading
}: OutgrowerDetailsInContractsListProps) => {
  return (
    <div className="flex items-center gap-3 rounded-xl bg-[#b3cbbd]/20 px-5 py-3">
      <Avatar className="h-[78px] w-[78px]" />
      <div>
        {isLoading || !outgrower ? (
          <>
            <Skeleton width={34} height={34} />
            <Skeleton width={34} height={34} />
          </>
        ) : (
          <div className="flex flex-col items-center gap-1">
            <Text
              color="wet-green"
              className="text-xl font-semibold leading-9 tracking-[0.15px]"
            >
              {outgrower.first_name} {outgrower.last_name}
            </Text>
            <Text
              color="wet-green"
              className="text-sm font-medium leading-[18px]"
            >
              Farm size: {formatSize(outgrower.farm_size)} ha
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutgrowerDetailsInContractsList;

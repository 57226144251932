import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import { SearchInput } from 'components/Filters';

interface DashboardSearchProps {
  filters: DashboardFiltersProps;
  onFilter: (filters: DashboardFiltersProps) => void;
}

const DashboardSearch = ({ filters, onFilter }: DashboardSearchProps) => {
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilter({
      search: event.target.value,
      crop: filters.crop,
      status: filters.status
    });
  };

  return (
    <SearchInput
      key={filters.search}
      defaultValue={filters.search}
      placeholder="Search by keyword"
      onSearch={onSearch}
    />
  );
};

export default DashboardSearch;

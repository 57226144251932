import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { contractHarvestRecordCreate } from 'entities/Contracts/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FormDateField, FormTextField } from 'components/Form';

interface ContractHarvestDialogProps {
  contractId: number;
  onClose: () => void;
}

export interface FormProps {
  harvest_date: dayjs.Dayjs;
  harvest_amount: number;
}

const ContractHarvestDialog = ({
  contractId,
  onClose
}: ContractHarvestDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>();

  const onSubmit = (data: any) => {
    const apiData = {
      harvest_date: data['harvest_date'].format('YYYY-MM-DD'),
      harvest_amount: data['harvest_amount']
    };
    return contractHarvestRecordCreate({
      contractId: contractId.toString(),
      data: apiData
    }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog onClose={onClose} title="Add Harvest">
      <div className="flex flex-col pt-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col gap-5"
        >
          <div className="flex gap-4">
            <FormDateField
              name="harvest_date"
              control={control}
              rules={{ required: 'This field is required' }}
              datePickerProps={{
                disableFuture: true,
                label: 'Harvest date'
              }}
              fieldProps={{
                placeholder: '',
                error: !!errors.harvest_date,
                helperText: errors.harvest_date?.message
              }}
            />
            <FormTextField
              name="harvest_amount"
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^\d+(\.\d{0,1})?$/, // Allows numbers with at most 1 decimal place
                  message: 'Only one decimal place is allowed'
                }
              }}
              fieldProps={{
                label: 'Harvest amount (t)',
                type: 'number',
                error: !!errors.harvest_amount,
                helperText: errors.harvest_amount?.message
              }}
            />
          </div>
          <div className="flex justify-center gap-3">
            <Button
              variant="outlined"
              className="font-medium"
              color="wetGreen"
              onClick={onClose}
            >
              Discard
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="font-medium"
              color="wetGreen"
            >
              Add
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContractHarvestDialog;

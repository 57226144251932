import { generatePath, useNavigate } from 'react-router-dom';

import { PenIcon, PersonIcon } from 'assets';

import { OutgrowerDetailResponse } from 'entities/Outgrower/sdk';

import Avatar from 'components/Avatar';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => {
  return (
    <Text color="wet-green" className="font-normal leading-5 tracking-[0.15px]">
      {children}
    </Text>
  );
};

interface ValueProps {
  children: React.ReactNode;
}
const Value = ({ children }: ValueProps) => {
  return (
    <Text
      color="wet-green"
      className="font-semibold leading-6 tracking-[0.15px]"
    >
      {children}
    </Text>
  );
};

interface OutgrowerProfileCardProps {
  outgrower: OutgrowerDetailResponse;
}

const OutgrowerProfileCard = ({ outgrower }: OutgrowerProfileCardProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col rounded-xl border border-solid border-[#D4DAE1] px-5 py-3">
      <div className="flex w-full items-center justify-between border-0 border-b border-solid border-b-[#D4DAE1] pb-2">
        <div className="flex items-center gap-2">
          <PersonIcon />
          <Text color="wet-green" className="font-semibold leading-[19.2px]">
            Profile
          </Text>
        </div>
        <div
          className="flex cursor-pointer items-center gap-1"
          onClick={() =>
            navigate(
              generatePath(URLS.OUTGROWER_UPDATE, {
                outgrowerId: outgrower.id.toString()
              })
            )
          }
        >
          <PenIcon />
          <Text
            color="wet-green"
            className="text-sm font-medium leading-[18px]"
          >
            Edit Profile
          </Text>
        </div>
      </div>

      <div className="flex gap-3 pt-5">
        <Avatar classes={{ root: 'w-[78px] h-[78px]' }} />
        <div className="flex gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-1">
              <Label>Name:</Label>
              <Value>
                {outgrower.first_name} {outgrower.last_name}
              </Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Gender:</Label>
              <Value>{outgrower.gender || '-'}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Date of Birth:</Label>
              <Value>{outgrower.birth_date || '-'}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>ID Number:</Label>
              <Value>{outgrower.id_number || '-'}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Email:</Label>
              <Value>{outgrower.email}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Phone:</Label>
              <Value>{outgrower.phone_number}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Company name:</Label>
              <Value>
                {outgrower.company_name ? `${outgrower.company_name}` : 'N/A'}
              </Value>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-1">
              <Label>Country:</Label>
              <Value>{outgrower.country}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Region:</Label>
              <Value>{outgrower.region}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Town:</Label>
              <Value>{outgrower.town}</Value>
            </div>
            <div className="flex items-center gap-1">
              <Label>Street:</Label>
              <Value>
                {outgrower.street || outgrower.house_number
                  ? `${outgrower.street || ''} ${outgrower.house_number || ''}`
                  : 'N/A'}
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgrowerProfileCard;

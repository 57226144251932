import { BASE_URL, post, useFetch } from 'utils/sdk';

export interface LoginResponse {
  token: string;
  user: {
    email: string;
  };
}
export const login = ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<LoginResponse> =>
  post(`${BASE_URL}/api/auth/login/`, { email, password });

export const logout = () => post(`${BASE_URL}/api/auth/logout/`);

export interface MeResponse {
  id: number;
  currency: string;
}
export const useMe = () => useFetch<MeResponse>(`/api/auth/me/`);

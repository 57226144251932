import {
  AttachMoneyInGreenIcon,
  CheckInGreenCircleIcon,
  DoneAllInGreenIcon,
  GrassInGreenIcon,
  SubjectInGreenIcon,
  TrendingUpInGreenIcon
} from 'assets';

import Skeleton from 'components/Skeleton';
import StatisticsCard from 'components/StatisticsCard';

const DashboardStatisticsLoading = () => {
  return (
    <div className="flex flex-row flex-wrap gap-3">
      <StatisticsCard
        className="flex-1"
        icon={<SubjectInGreenIcon />}
        title="Active Contracts"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={<CheckInGreenCircleIcon />}
        title="Received Тask"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={<DoneAllInGreenIcon />}
        title="Understood Тask"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={<GrassInGreenIcon />}
        title="Expected Harvest"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={<AttachMoneyInGreenIcon />}
        title="Financial Exposure"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-2"
        icon={<TrendingUpInGreenIcon />}
        title="Expected Farmgate Price"
        value={
          <div>
            <Skeleton />
          </div>
        }
      />
    </div>
  );
};

export default DashboardStatisticsLoading;

import { CloseIcon } from 'assets';

import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import Button from 'components/Button';

interface ClearAllFiltersButtonProps {
  filters: DashboardFiltersProps;
  onFilter: (filters: DashboardFiltersProps) => void;
  resetSort: () => void;
}

const ClearAllFiltersButton = ({
  onFilter,
  filters,
  resetSort
}: ClearAllFiltersButtonProps) => {
  const onClear = () => {
    onFilter({ crop: filters.crop });
    resetSort();
  };
  return (
    <Button
      variant="outlined"
      className="h-8 border border-solid border-[#d0d5dd] font-medium text-wet-green"
      endIcon={<CloseIcon width={15} height={15} />}
      onClick={onClear}
    >
      Clear all filters
    </Button>
  );
};

export default ClearAllFiltersButton;

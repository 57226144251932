import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { AssignmentIcon, LogoutIcon, PeopleIcon, WhiteLogoIcon } from 'assets';
import { isNull } from 'lodash';
import { setSentryUser } from 'sentry';

import { logout } from 'entities/Auth/sdk';

import IconButton from 'components/IconButton';
import NavigationTab from 'components/SideNavigation/NavigationTab';

import { removeToken } from 'utils/storage';

import { URLS } from 'config/urls';

const SideNavigation = () => {
  const [open, setOpened] = useState(false);
  const location = useLocation();
  const { outgrowerId, contractId } = useParams();
  const navigate = useNavigate();

  const [contractsTabExpanded, setContractsTabExpanded] = useState(false);

  useEffect(() => {
    // The navigation is opened only on certain pages.
    // The navigation cannot be opened/ closed from the user, following the current design.
    if (
      [
        URLS.OUTGROWER_PROFILE,
        URLS.OUTGROWER_CONTRACTS,
        URLS.OUTGROWER_CONTRACT_DIARY,
        URLS.OUTGROWER_CONTRACT_GALLERY
      ].some((url) => !isNull(matchPath(url, location.pathname)))
    ) {
      setOpened(true);
    }

    if (
      [URLS.OUTGROWERS, URLS.DASHBOARD, URLS.CONTRACTS].some(
        (url) => !isNull(matchPath(url, location.pathname))
      )
    ) {
      setOpened(false);
    }

    if (
      [URLS.OUTGROWER_CONTRACT_DIARY, URLS.OUTGROWER_CONTRACT_GALLERY].some(
        (url) => !isNull(matchPath(url, location.pathname))
      )
    ) {
      setContractsTabExpanded(true);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    return logout().then(() => {
      removeToken();
      setSentryUser({ email: '' });
      navigate(URLS.LOGIN);
    });
  };

  return (
    <>
      <div
        className={`flex h-full w-[105px] flex-col items-center gap-[13px] bg-wet-green px-2 py-5`}
      >
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 flex-col items-center gap-2">
            <NavigationTab to={URLS.DASHBOARD}>
              <WhiteLogoIcon /> <div>Dashboard</div>
            </NavigationTab>

            <NavigationTab to={URLS.OUTGROWERS}>
              <PeopleIcon /> <div>Outgrowers</div>
            </NavigationTab>
            <NavigationTab to={URLS.CONTRACTS}>
              <AssignmentIcon /> Contracts
            </NavigationTab>
          </div>
          <div className="flex items-center justify-center px-3 leading-[0]">
            <IconButton
              onClick={handleLogout}
              aria-label="logout"
              classes={{ root: 'py-0' }}
            >
              <LogoutIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {open && outgrowerId && (
        <div className="flex w-[90px] flex-col items-start gap-2 border-0 border-l border-solid border-[#b3cbbd]/50 bg-wet-green px-2 py-[30px]">
          <NavigationTab
            className="mt-[80px]"
            to={generatePath(URLS.OUTGROWER_PROFILE, {
              outgrowerId: outgrowerId as string
            })}
          >
            Profile
          </NavigationTab>
          <NavigationTab
            to={generatePath(URLS.OUTGROWER_CONTRACTS, {
              outgrowerId: outgrowerId as string
            })}
          >
            Contracts
          </NavigationTab>
          {contractsTabExpanded && outgrowerId && contractId && (
            <>
              <NavigationTab
                to={generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                  outgrowerId: outgrowerId as string,
                  contractId: contractId as string
                })}
                state={{ url: location.pathname }}
                className="ml-3 w-auto"
              >
                Diary
              </NavigationTab>
              <NavigationTab
                to={generatePath(URLS.OUTGROWER_CONTRACT_GALLERY, {
                  outgrowerId: outgrowerId as string,
                  contractId: contractId as string
                })}
                className="ml-3 w-auto"
              >
                Gallery
              </NavigationTab>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SideNavigation;

import {
  FileUploadIcon,
  PeopleBlackIcon,
  PhoneIcon,
  PhotoAddIcon
} from 'assets';

import { FormProps } from 'entities/Outgrower/components/ProfileCreateForm';
import {
  GENDER_OPTIONS,
  LEAD_FARMER_OPTIONS
} from 'entities/Outgrower/constants';
import { CountryOption, OutgrowerListResponse } from 'entities/Outgrower/sdk';

import Avatar from 'components/Avatar';
import {
  FormAutoCompleteField,
  FormDateField,
  FormRadioField,
  FormSelectField,
  FormTextField
} from 'components/Form';
import FormCountryField from 'components/Form/CountryField';
import FormPhoneField from 'components/Form/PhoneField';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';
import TextField from 'components/TextField';

import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch
} from 'utils/forms';

interface OutgrowerInformationProps {
  control: Control<FormProps>;
  errors: FieldErrors<FormProps>;
  watch: UseFormWatch<FormProps>;
  leadOutgrowers?: Array<OutgrowerListResponse>;
  countries: Array<CountryOption>;
  setValue: UseFormSetValue<FormProps>;
}

const OutgrowerInformation = ({
  control,
  errors,
  watch,
  setValue,
  leadOutgrowers,
  countries
}: OutgrowerInformationProps) => {
  const isLeadValue = watch('is_lead');

  const leadOutgrowersOptions = leadOutgrowers?.map((option) => ({
    label: `${option.first_name} ${option.last_name}, ${option.phone_number}`,
    value: option.id
  }));

  return (
    <div className="flex flex-1 flex-col gap-3">
      <Text
        classes={{
          root: 'text-xl font-semibold  text-wet-green leading-8 tracking-[0.15px]'
        }}
      >
        Outgrower Information
      </Text>
      <div className="flex flex-col gap-6 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-6">
          <Avatar className="h-[90px] w-[90px] cursor-pointer border border-dashed border-fresh-green bg-white">
            <FileUploadIcon />
          </Avatar>
          <div className="flex flex-1 flex-col gap-2">
            <div className="flex items-center gap-2">
              <PhotoAddIcon />
              <Text className="text-base font-semibold leading-[19.2px]">
                Profile photo
              </Text>
            </div>
            <div>
              <Text className="text-sm font-bold leading-[21px]">
                Click the icon to upload profile picture
              </Text>
              <Text className="text-xs font-normal leading-[21px]">
                PNG or JPG (max. 5 MB)
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-2">
            <PhoneIcon />
            <Text className="font-base font-semibold leading-[19.2px]">
              Contacts
            </Text>
          </div>
          <div className="flex gap-3">
            <div className="flex w-1/2 flex-col gap-4">
              <FormTextField
                name="first_name"
                control={control}
                rules={{ required: 'This field is required' }}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 1
                    }
                  },
                  label: 'First name*',
                  error: !!errors.first_name,
                  helperText: errors.first_name?.message
                }}
              />
              <FormSelectField
                name="gender"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 3
                    }
                  },
                  id: 'farm_land_type',
                  label: 'Gender',
                  select: true,
                  error: !!errors.gender,
                  helperText: errors.gender?.message
                }}
              >
                {GENDER_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormSelectField>
              <FormTextField
                name="id_number"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 4
                    }
                  },
                  label: 'ID Number',
                  error: !!errors.id_number,
                  helperText: errors.id_number?.message
                }}
              />
              <FormPhoneField
                name="phone_number"
                control={control}
                rules={{
                  required: 'This field is required'
                }}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 5
                    }
                  },
                  label: 'Phone number*',
                  error: !!errors.phone_number,
                  helperText: errors.phone_number?.message
                }}
              />
              <FormTextField
                name="company_name"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 6
                    }
                  },
                  label: 'Company name (if applicable)',
                  error: !!errors.company_name,
                  helperText: errors.company_name?.message
                }}
              />
              <FormTextField
                name="region"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 8
                    }
                  },
                  label: 'Region / State / Province (if applicable)',
                  error: !!errors.region,
                  helperText: errors.region?.message
                }}
              />
              <FormTextField
                name="street"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 10
                    }
                  },
                  label: 'Street',
                  error: !!errors.street,
                  helperText: errors.street?.message
                }}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <FormTextField
                name="last_name"
                control={control}
                rules={{ required: 'This field is required' }}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 2
                    }
                  },
                  label: 'Last name*',
                  error: !!errors.last_name,
                  helperText: errors.last_name?.message
                }}
              />
              <FormDateField
                name="birth_date"
                control={control}
                datePickerProps={{
                  label: 'Date of Birth',
                  slotProps: {
                    textField: {
                      classes: {
                        root: 'w-full'
                      }
                    }
                  }
                }}
                fieldProps={{
                  placeholder: '',
                  error: !!errors.birth_date,
                  helperText: errors.birth_date?.message
                }}
              />
              <FormTextField
                name="email"
                control={control}
                rules={{ required: 'This field is required' }}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 4
                    }
                  },
                  type: 'email',
                  label: 'Email*',
                  error: !!errors.email,
                  helperText: errors.email?.message
                }}
              />
              <FormCountryField
                countries={countries}
                control={control}
                fieldProps={{
                  name: 'country',
                  label: 'Country*',
                  error: !!errors.country,
                  helperText: errors.country?.message
                }}
              />
              <FormTextField
                name="town"
                control={control}
                rules={{ required: 'This field is required' }}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 7
                    }
                  },
                  label: 'Town*',
                  error: !!errors.town,
                  helperText: errors.town?.message
                }}
              />
              <FormTextField
                name="house_number"
                control={control}
                fieldProps={{
                  slotProps: {
                    htmlInput: {
                      tabIndex: 9
                    }
                  },
                  type: 'text',
                  label: 'House number',
                  error: !!errors.house_number,
                  helperText: errors.house_number?.message
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <PeopleBlackIcon />
            <Text className="font-base font-semibold leading-[19.2px]">
              Lead farmer
            </Text>
          </div>
          <div className="flex items-center">
            <Text className="w-1/2 text-sm font-normal leading-[16.8px]">
              Is this Outgrower a Lead farmer?
            </Text>

            <FormRadioField
              name="is_lead"
              control={control}
              options={LEAD_FARMER_OPTIONS}
              className="w-1/2 px-3"
              radioGroupProps={{
                row: true,
                defaultValue: 'yes',
                className: 'gap-4',
                onChange: (value) => {
                  if (value === 'true') {
                    setValue('lead_outgrower', undefined);
                  }
                }
              }}
            />
          </div>
        </div>

        {isLeadValue === 'false' && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <Text className="w-1/2 text-sm font-normal leading-[16.8px]">
                Select a Lead farmer (optional)
              </Text>

              <div className="w-1/2">
                <FormAutoCompleteField
                  name="lead_outgrower"
                  control={control}
                  fieldProps={{
                    options: leadOutgrowersOptions || [],
                    className: 'w-full',
                    renderInput: (params: any) => (
                      <TextField
                        {...params}
                        label="Lead farmer (optional)"
                        error={!!errors.lead_outgrower}
                        helperText={errors.lead_outgrower?.message}
                      />
                    )
                  }}
                ></FormAutoCompleteField>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutgrowerInformation;

import { generatePath, useNavigate } from 'react-router-dom';

import { CONTRACT_STATUSES } from 'entities/Contracts/constants';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface ContractCreatedDialogProps {
  outgrowerId: string;
}

const ContractCreatedDialog = ({ outgrowerId }: ContractCreatedDialogProps) => {
  const navigate = useNavigate();
  return (
    <Dialog title="Contract added">
      <div className="py-8">
        <Text
          classes={{
            root: 'tracking-[0.15px] text-wet-green leading-[30px] font-medium text-xl pb-5'
          }}
        >
          You’ve successfully added new Contract.
        </Text>
      </div>

      <div className="flex justify-center gap-3">
        <Button
          variant="contained"
          className="font-medium"
          color="wetGreen"
          onClick={() =>
            navigate(
              generatePath(URLS.OUTGROWER_CONTRACTS, {
                outgrowerId: outgrowerId.toString()
              }),
              {
                state: { status: CONTRACT_STATUSES.UPCOMING }
              }
            )
          }
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default ContractCreatedDialog;

import { useCallback, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import ContractForm from 'entities/Contracts/components/ContractCreateForm/ContractForm';
import ContractPreview from 'entities/Contracts/components/ContractCreateForm/ContractPreview';
import {
  ContractCreateProps,
  ContractSummaryPreviewProps,
  ContractSummaryPreviewResponse,
  contractSummaryPreview
} from 'entities/Contracts/sdk';
import { CropChoicesListResponse } from 'entities/Crops/sdk';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useForm } from 'utils/forms';

import { URLS } from 'config/urls';

export interface FormProps {
  crop: number;
  crop_variety: number | undefined;
  contract_land_size: number;
  signature_date: string;
  execution_start_date: string;
  expected_harverst_date: dayjs.Dayjs;
}

interface ContractCreateFormProps {
  onSave: (data: any) => Promise<any>;
  crops: Array<CropChoicesListResponse>;
  outgrowerId: number;
  outgrowerFarms: Array<OutgrowerFarmResponse>;
}

const ContractCreateForm = ({
  onSave,
  crops,
  outgrowerId,
  outgrowerFarms
}: ContractCreateFormProps) => {
  const navigate = useNavigate();

  const {
    control,
    getValues,
    setValue,
    watch,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>();

  const farm: OutgrowerFarmResponse = outgrowerFarms[0]; // An outgrower can have only one farm;

  const [contractDataFilledIn, setContractDataFilledIn] = useState(false);
  const [summary, setSummary] = useState<ContractSummaryPreviewResponse>();
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    // In case of inactivity, we don't want to block the navigation
    if (blockNavigation && nextLocation.pathname == URLS.LOGIN) {
      return false;
    }

    if (blockNavigation && nextLocation.pathname !== currentLocation.pathname) {
      setShowConfirmationDialog(true);
      return true;
    }
    return false;
  });

  const confirmNavigation = () => {
    setShowConfirmationDialog(false);

    if (blocker.state == 'blocked') {
      blocker.proceed();
    }
  };

  const cancelNavigation = () => {
    setShowConfirmationDialog(false);

    if (blocker.state == 'blocked') {
      blocker.reset();
    }
  };

  const onSubmit = (data: FormProps) => {
    setBlockNavigation(false);

    const submitData: ContractCreateProps = {
      outgrower_id: outgrowerId,
      crop_id: data.crop,
      crop_variety_id: data.crop_variety as number,
      farm_id: farm.id,
      land_size: data.contract_land_size,
      signature_date: dayjs(data.signature_date).format('YYYY-MM-DD'),
      execution_start_date: dayjs(data.execution_start_date).format(
        'YYYY-MM-DD'
      ),
      expected_harverst_date: data.expected_harverst_date.format('YYYY-MM-DD')
    };

    onSave(submitData).catch(({ setFormErrors }) => setFormErrors(setError));
  };

  const handleShowPreview = useCallback(async () => {
    const cropId = getValues('crop');
    const cropVarietyId = getValues('crop_variety');
    const landSize = getValues('contract_land_size');
    const farmId = farm.id;
    const contractSummary: ContractSummaryPreviewProps = {
      outgrower_id: outgrowerId,
      crop_id: cropId,
      crop_variety_id: cropVarietyId as number,
      farm_id: farmId,
      land_size: landSize,
      signature_date: dayjs(getValues('signature_date')).format('YYYY-MM-DD'),
      execution_start_date: dayjs(getValues('execution_start_date')).format(
        'YYYY-MM-DD'
      ),
      expected_harverst_date: dayjs(getValues('expected_harverst_date')).format(
        'YYYY-MM-DD'
      )
    };
    contractSummaryPreview(contractSummary)
      .then((data: ContractSummaryPreviewResponse) => {
        setSummary(data);
        setContractDataFilledIn(true);
      })
      .catch(({ setFormErrors }) => setFormErrors(setError));
  }, [farm.id, getValues, outgrowerId, setError]);

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        <div className="flex-1 overflow-y-auto px-[26px] pt-5">
          <div className="flex gap-3">
            {!contractDataFilledIn && (
              <ContractForm
                crops={crops}
                errors={errors}
                control={control}
                setValue={setValue}
                watch={watch}
                farm={farm}
              />
            )}
            {contractDataFilledIn && summary && (
              <ContractPreview summary={summary} />
            )}
          </div>
        </div>
        <div className="flex h-[76px] items-end justify-end gap-4 p-4 shadow-[6px_0_4px_-1px_#D9D9D9]">
          {
            <Button
              variant="outlined"
              color="wetGreen"
              className="w-[178px]"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          }

          {!contractDataFilledIn && (
            <Button
              className="w-[178px]"
              variant="contained"
              color="wetGreen"
              onClick={() => handleSubmit(handleShowPreview)()}
            >
              Next
            </Button>
          )}

          {contractDataFilledIn && (
            <Button
              variant="outlined"
              color="wetGreen"
              className="w-[178px]"
              onClick={() => setContractDataFilledIn(false)}
            >
              Back
            </Button>
          )}

          {contractDataFilledIn && (
            <Button
              className="w-[178px]"
              type="submit"
              variant="contained"
              color="wetGreen"
            >
              Create Contract
            </Button>
          )}
        </div>
      </form>

      {showConfirmationDialog && (
        <ConfirmationDialog
          title={'Unsaved Changes'}
          message={
            'Are you sure you want to leave this page? Any unsaved data will be lost.'
          }
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
          confirmMessage={'Yes'}
          cancelMessage={'No'}
        />
      )}
    </div>
  );
};

export default ContractCreateForm;

import Skeleton from 'components/Skeleton';
import StatisticsCard from 'components/StatisticsCard';

const ContractDiaryStatisticsLoading = () => {
  return (
    <div className="flex w-full flex-row gap-3">
      <StatisticsCard
        className="flex-1"
        icon={
          <div className="w-[20px]">
            <Skeleton />
          </div>
        }
        title={''}
        value={
          <div className="w-full">
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={
          <div className="w-[20px]">
            <Skeleton />
          </div>
        }
        title={''}
        value={
          <div className="w-full">
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={
          <div className="w-[20px]">
            <Skeleton />
          </div>
        }
        title={''}
        value={
          <div className="w-full">
            <Skeleton />
          </div>
        }
      />
      <StatisticsCard
        className="flex-1"
        icon={
          <div className="w-[20px]">
            <Skeleton />
          </div>
        }
        title={''}
        value={
          <div className="w-full">
            <Skeleton />
          </div>
        }
      />
    </div>
  );
};

export default ContractDiaryStatisticsLoading;

import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useNavigate } from 'react-router-dom';

import { setSentryUser } from 'sentry';

import { logout } from 'entities/Auth/sdk';

import { removeToken } from 'utils/storage';
import { getToken } from 'utils/storage';

import { URLS } from 'config/urls';

const AuthGuard = () => {
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    if (!token) {
      navigate(URLS.LOGIN);
    }
  }, [navigate, token]);

  const handleOnIdle = () => {
    logout().then(() => {
      removeToken();
      setSentryUser({ email: '' });
      navigate(URLS.LOGIN);
    });
  };

  useIdleTimer({
    timeout: 10 * 60 * 1000, // 10 minutes in milliseconds
    onIdle: handleOnIdle
  });

  return <Outlet />;
};

export default AuthGuard;

import { BASE_URL, SortRequestProps, post, useFetch } from 'utils/sdk';

interface FarmCreateProps {
  name?: string;
  number?: string;
  ownership: string;
  soil_type: string;
  irrigation: boolean;
  region?: string;
  street?: string;
  size: number;
  country: string;
  town: string;
  street_number?: string;
}

export interface OutgrowerCreateProps {
  first_name: string;
  last_name: string;
  id_number?: string;
  gender?: string;
  birth_date?: string;
  email: string;
  phone_number: string;
  company_name?: string;
  region?: string;
  street?: string;
  country: string;
  town: string;
  house_number?: string;
  is_lead: boolean;
  lead_outgrower?: number;
  farms_data: Array<FarmCreateProps>;
}

export interface OutgrowerCreateResponse {
  id: number;
  first_name: string;
  last_name: string;
}

export const outgrowerCreate = (data: OutgrowerCreateProps) =>
  post<OutgrowerCreateResponse>(`${BASE_URL}/api/outgrowers/create/`, data);

export interface OutgrowerUpdateDetailsProps {
  first_name: string;
  last_name: string;
  id_number?: string;
  gender?: string;
  birth_date?: string;
  email: string;
  phone_number: string;
  company_name?: string;
  region?: string;
  street?: string;
  country: string;
  town: string;
  house_number?: string;
  is_lead: boolean;
  lead_outgrower?: number;
  farm_name?: string;
  farm_number?: string;
  farm_ownership: string;
  farm_soil_type: string;
  farm_irrigation: boolean;
  farm_region?: string;
  farm_street?: string;
  farm_size: string;
  farm_country: string;
  farm_town: string;
  farm_street_number?: string;
  farm_active_land_size: string;
}

export const useOutgrowerUpdateDetails = ({
  outgrowerId
}: {
  outgrowerId?: string;
}) =>
  useFetch<OutgrowerUpdateDetailsProps>(
    `/api/outgrowers/${outgrowerId}/update/details/`
  );

export interface OutgrowerUpdateProps {
  first_name: string;
  last_name: string;
  id_number?: string;
  gender?: string;
  birth_date?: string;
  email: string;
  phone_number: string;
  company_name?: string;
  region?: string;
  street?: string;
  country: string;
  town: string;
  house_number?: string;
  is_lead: boolean;
  lead_outgrower?: number;
  farm_name?: string;
  farm_number?: string;
  farm_ownership: string;
  farm_soil_type: string;
  farm_irrigation: boolean;
  farm_region?: string;
  farm_street?: string;
  farm_size: string;
  farm_country: string;
  farm_town: string;
  farm_street_number?: string;
}

export interface OutgrowerUpdateResponse {
  id: number;
  first_name: string;
  last_name: string;
}

export const outgrowerUpdate = (
  data: OutgrowerUpdateProps,
  outgrowerId?: string
) =>
  post<OutgrowerUpdateResponse>(
    `${BASE_URL}/api/outgrowers/${outgrowerId}/update/`,
    data
  );

export interface OutgrowerListFilterProps {
  search?: string;
}

export interface OutgrowerListSortProps {
  sort?: string;
}

export interface OutgrowerListResponse {
  id: number;
  first_name: string;
  last_name: string;
  country: string;
  town: string;
  email: string;
  phone_number: string;
  active_contracts: number;
  is_lead: boolean;
  farms: [
    {
      name: string;
      number: string;
      contracts: [
        {
          id: number;
          crop: string;
        }
      ];
    }
  ];
}

export const useOutgrowersList = ({
  filters,
  sort
}: {
  filters?: OutgrowerListFilterProps;
  sort?: SortRequestProps;
} = {}) =>
  useFetch<Array<OutgrowerListResponse>>('/api/outgrowers/', {
    ...filters,
    ...sort
  });

export interface CountryOption {
  value: string;
  label: string;
}

export const useCountriesList = () =>
  useFetch<Array<CountryOption>>('/api/common/countries/');

export interface OutgrowerFarmResponse {
  id: number;
  name: string;
  number: string;
  size: number;
  irrigation: boolean;
  country: string;
  town: string;
  region: string;
  street: string;
  street_number: string;
  ownership: string;
  soil_type: string;

  outgrower: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export const useOutgrowerFarms = ({ outgrowerId }: { outgrowerId?: string }) =>
  useFetch<Array<OutgrowerFarmResponse>>(
    `/api/outgrowers/${outgrowerId}/farms/`
  );

export interface OutgrowerDetailResponse {
  id: number;
  first_name: string;
  last_name: string;
  gender: string;
  id_number: string;
  birth_date: string;
  email: string;
  phone_number: string;
  company_name: string;
  country: string;
  town: string;
  region: string;
  street: string;
  house_number: string;
  active_contracts: number;
  upcoming_contracts: number;
  closed_contracts: number;

  farms: [
    {
      id: number;
      name: string;
      number: string;
      size: number;
      irrigation: boolean;
      country: string;
      town: string;
      region: string;
      street: string;
      street_number: string;
      ownership: string;
      soil_type: string;
      contracts: [
        {
          id: number;
          crop: string;
        }
      ];
    }
  ];
}

export const useOutgrowerDetail = ({
  outgrowerId
}: {
  outgrowerId: string | undefined;
}) => useFetch<OutgrowerDetailResponse>(`/api/outgrowers/${outgrowerId}/`);

export interface OutgrowerContractsListFiltersProps {
  status: string;
  search?: string;
}

export interface OutgrowerContractsListResponse {
  outgrower: {
    id: number;
    first_name: string;
    last_name: string;
    farm_size: number;
  };
  contracts: Array<{
    id: number;
    crop: string;
    execution_start_date: string;
    expected_harverst_date: string;
    actual_harvest_date: string;
    expected_harvest_amount: string;
    actual_harvest_amount: string;
    expected_farmgate_price: string;
    exposure: string;
    termination_reason: string | null;
    land_size: number;
  }>;
}

export const useOutgrowerContractsList = ({
  outgrowerId,
  filters,
  sort
}: {
  outgrowerId: string | undefined;
  filters?: OutgrowerContractsListFiltersProps;
  sort?: SortRequestProps;
}) =>
  useFetch<OutgrowerContractsListResponse>(
    `/api/outgrowers/${outgrowerId}/contracts/`,
    {
      ...filters,
      ...sort
    }
  );

export interface OutgrowerDetailsResponse {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  town: string;
  country: string;
  lead_outgrower: {
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  farms: [
    {
      id: number;
      name: string;
      size: number;
      soil_type: string;
      irrigation: boolean;
      town: string;
      country: string;
    }
  ];
}

export const useOutgrowerDetails = ({
  outgrowerId
}: {
  outgrowerId: string | undefined;
}) =>
  useFetch<OutgrowerDetailsResponse>(`/api/outgrowers/${outgrowerId}/details/`);

import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ErrorIcon } from 'assets';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

import { ContractDiaryProps } from 'entities/Contracts/sdk';

import Card from 'components/Card';
import Chip from 'components/Chip';
import ImageCard from 'components/ImageCard';
import ImageDialog from 'components/ImageDialog';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface ContractDiaryGalleryProps {
  contractDiary?: ContractDiaryProps;
  contractId?: string;
  outgrowerId?: string;
}

const ContractDiaryGallery = ({
  contractDiary,
  outgrowerId,
  contractId
}: ContractDiaryGalleryProps) => {
  const navigate = useNavigate();

  const lastImage = orderBy(
    contractDiary?.image_attachments,
    ['created_at'],
    ['desc']
  )[0];

  const lastUpdatedAt = dayjs(lastImage?.created_at).format('DD.MM.YYYY');

  const galleryIsEmpty = contractDiary?.image_attachments.length === 0;

  const [imageUrlOpened, setImageUrlOpened] = useState<string | null>(null);

  return (
    <div>
      <Card
        classes={{
          root: 'flex cursor-pointer flex-col p-4 gap-3 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Text className="text-lg font-semibold leading-[28.8px] tracking-[0.15px] text-wet-green">
              Gallery
            </Text>
            {!galleryIsEmpty && (
              <Chip
                className="h-7 rounded-[50px] bg-dry-soil px-3 text-xs leading-[18px] text-wet-green"
                label={
                  <>
                    <span className="font-normal">Last updated on:</span>{' '}
                    <span className="font-semibold">{lastUpdatedAt}</span>
                  </>
                }
              />
            )}
          </div>
          {!galleryIsEmpty && (
            <Text
              className="cursor-pointer text-sm font-semibold leading-5 tracking-[0.15px] text-wet-green"
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_GALLERY, {
                    outgrowerId: outgrowerId as string,
                    contractId: contractId as string
                  })
                )
              }
            >
              See All
            </Text>
          )}
        </div>
        <div className="flex h-[136px] flex-wrap gap-3 overflow-hidden">
          {contractDiary?.image_attachments.map((image) => (
            <ImageCard
              alt={'Creation of image'}
              url={image.file_url}
              date={image.created_at}
              width="142px"
              height="136px"
              onClick={() => setImageUrlOpened(image.file_url)}
            />
          ))}
          {galleryIsEmpty && (
            <div className="flex w-full flex-col items-center justify-center gap-2">
              <ErrorIcon />
              <Text
                color="wet-green"
                className="text-sm font-semibold leading-[30px] tracking-[0.15px]"
              >
                No photos available
              </Text>
            </div>
          )}
        </div>
      </Card>
      {imageUrlOpened && (
        <ImageDialog
          imageUrl={imageUrlOpened}
          onClose={() => setImageUrlOpened(null)}
        />
      )}
    </div>
  );
};

export default ContractDiaryGallery;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutgrowerProfileUpdateDialog from 'entities/Outgrower/components/ProfileUpdateDialog';
import OutgrowerProfileUpdateForm from 'entities/Outgrower/components/ProfileUpdateForm';
import {
  OutgrowerUpdateProps,
  OutgrowerUpdateResponse,
  outgrowerUpdate,
  useCountriesList,
  useOutgrowerUpdateDetails,
  useOutgrowersList
} from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerUpdatePage = () => {
  const { setPageTitle } = useLayout();
  const { outgrowerId } = useParams();

  const {
    data: outgrowerUpdateDetails,
    isLoading: outgrowerUpdateDetailsLoading,
    isValidating: outgrowerUpdateDetailsIsValidating
  } = useOutgrowerUpdateDetails({
    outgrowerId
  });

  const { data: outgrowers, isLoading: outgrowersAreLoading } =
    useOutgrowersList();
  const { data: countries, isLoading: countriesAreLoading } =
    useCountriesList();

  const [
    outgrowerProfileUpdateDialogOpened,
    setOutgrowerProfileUpdateDialogOpened
  ] = useState(false);

  const [updatedOutgrower, setUpdatedOutgrower] =
    useState<OutgrowerUpdateResponse>();

  useEffect(() => {
    setPageTitle(<PageTitle text="Edit Outgrower Profile"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const handleSave = (data: OutgrowerUpdateProps) => {
    return outgrowerUpdate(data, outgrowerId).then(
      (submitResponse: OutgrowerUpdateResponse) => {
        setUpdatedOutgrower(submitResponse);
        setOutgrowerProfileUpdateDialogOpened(true);
      }
    );
  };

  const isLoading =
    outgrowerUpdateDetailsLoading ||
    outgrowerUpdateDetailsIsValidating ||
    outgrowersAreLoading ||
    countriesAreLoading;

  return (
    <div className="h-full">
      {isLoading && <ContentLoading />}
      {!isLoading && outgrowerUpdateDetails && outgrowers && countries && (
        <OutgrowerProfileUpdateForm
          outgrower={outgrowerUpdateDetails}
          outgrowers={outgrowers}
          countries={countries}
          onSave={handleSave}
        />
      )}
      {outgrowerProfileUpdateDialogOpened && updatedOutgrower && (
        <OutgrowerProfileUpdateDialog outgrower={updatedOutgrower} />
      )}
    </div>
  );
};

export default OutgrowerUpdatePage;

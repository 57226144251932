import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { ContractsListFiltersProps } from 'entities/Contracts/sdk';
import { OutgrowerContractsListResponse } from 'entities/Outgrower/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';
import Tooltip from 'components/Tooltip';

import { formatMoney, formatUnitAmount } from 'utils/numbers';
import { ListSortProps } from 'utils/sdk';

import { URLS } from 'config/urls';

interface ClosedOutgrowerContractsListTableProps {
  contracts: OutgrowerContractsListResponse['contracts'];
  outgrower: OutgrowerContractsListResponse['outgrower'];
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  filters: ContractsListFiltersProps;
  currency: string;
}

const ClosedOutgrowerContractsListTable = ({
  contracts,
  outgrower,
  onSort,
  sort,
  filters,
  currency
}: ClosedOutgrowerContractsListTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tableIsEmpty = contracts.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="px-3"
            />
            <TableHeaderCell content="Crop" className="px-3" />
            <SortableTableHeaderCell
              content="Land Size"
              onSort={onSort}
              sortBy={sort}
              sortName={'land_size'}
            />
            <SortableTableHeaderCell
              content="Start Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'execution_start_date'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Expected Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harverst_date'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Actual Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'actual_harvest_date'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content="Actual Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'actual_harvest_amount'}
              className="px-3"
            />
            <SortableTableHeaderCell
              content={`Exposure (${currency})`}
              onSort={onSort}
              sortBy={sort}
              sortName={'exposure'}
              className="px-3"
            />
            <TableHeaderCell content="Closing Reason" />
            <TableHeaderCell content="Status" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState
              tableColumns={11}
              text={'No contracts available'}
            />
          )}
          {contracts.map((contract) => (
            <TableRow
              hover
              onClick={() =>
                navigate(
                  generatePath(URLS.OUTGROWER_CONTRACT_DIARY, {
                    contractId: contract.id.toString(),
                    outgrowerId: outgrower.id.toString()
                  }),
                  { state: { filters, sort, url: location.pathname } }
                )
              }
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
              }}
            >
              <TableBodyCell content={contract.id} />
              <TableBodyCell content={contract.crop} className="font-medium" />
              <TableBodyCell content={`${contract.land_size} ha`} />
              <TableBodyCell content={contract.execution_start_date} />
              <TableBodyCell content={contract.expected_harverst_date} />
              <TableBodyCell content={contract.actual_harvest_date || 'N/A'} />
              <TableBodyCell
                content={`${formatUnitAmount(contract.expected_harvest_amount)} t`}
              />
              <TableBodyCell
                content={`${formatUnitAmount(contract.actual_harvest_amount || '0')} t`}
              />
              <TableBodyCell
                content={`${formatMoney(contract.exposure || '0')}`}
              />
              <TableBodyCell
                content={
                  contract.termination_reason ? (
                    <Tooltip
                      className="text-xs text-wet-green"
                      title={contract.termination_reason}
                    >
                      <div>Cancelled</div>
                    </Tooltip>
                  ) : (
                    'Season end'
                  )
                }
              />
              <TableBodyCell content={'N/A'} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClosedOutgrowerContractsListTable;

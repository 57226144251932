import { useState } from 'react';

import { MaizeIcon, SorghumIcon, SugarcaneIcon, TobaccoIcon } from 'assets';
import { get } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { FormProps } from 'entities/Contracts/components/ContractCreateForm';
import { CropChoicesListResponse } from 'entities/Crops/sdk';

import ClickableChip from 'components/ClickableChip';

import { UseFormSetValue, UseFormWatch } from 'utils/forms';

interface ICropChipList {
  crops: Array<CropChoicesListResponse>;
  className?: string;
  setValue: UseFormSetValue<FormProps>;
  onCropClick: (cropId: number | null) => void;
  watch: UseFormWatch<FormProps>;
}

type IconKey = 'Maize' | 'Sugarcane' | 'Tobacco' | 'Sorghum';

const iconMapper: Record<
  IconKey,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  Maize: MaizeIcon,
  Sugarcane: SugarcaneIcon,
  Tobacco: TobaccoIcon,
  Sorghum: SorghumIcon
};

interface IconRendererProps {
  iconName: string;
  className?: string;
}

const IconRenderer: React.FunctionComponent<IconRendererProps> = ({
  iconName,
  className
}) => {
  const IconComponent = get(iconMapper, iconName, MaizeIcon);
  return <IconComponent className={className} />;
};

const CropChipList = ({
  crops,
  className,
  setValue,
  onCropClick,
  watch
}: ICropChipList) => {
  const defaultCropId = watch ? watch('crop') : null;

  const [clickedCropId, setClickedCropId] = useState<number | null>(
    defaultCropId || null
  );

  const handleChipClick = (chipKey: number | string) => {
    const cropId = chipKey as number;

    setValue('crop', cropId);
    setValue('crop_variety', undefined);
    setClickedCropId((prev) => (prev === cropId ? null : cropId));

    onCropClick(cropId);
  };

  return (
    <div
      className={twMerge(
        'shadow-[rgba(16, 24, 40, 0.05)] flex gap-2 rounded-xl border border-solid border-[#d4dae1] bg-white p-3 shadow-sm',
        className
      )}
    >
      {crops.map((crop) => (
        <ClickableChip
          key={crop.crop_id}
          chipKey={crop.crop_id}
          icon={<IconRenderer iconName={crop.name} />}
          label={crop.name}
          classes={{
            root: 'border border-solid border-[#062D29] px-4 py-[7px] m-0 text-sm'
          }}
          isClicked={clickedCropId === crop.crop_id}
          onClick={handleChipClick}
        />
      ))}
    </div>
  );
};

export default CropChipList;

import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import { ContractsListFiltersProps } from 'entities/Contracts/sdk';
import ClosedOutgrowerContractsListTable from 'entities/Outgrower/components/OutgrowerContractsListTable/ClosedOutgrowerContractsListTable';
import UpcomingOutgrowerContractsListTable from 'entities/Outgrower/components/OutgrowerContractsListTable/UpcomingOutgrowerContractsListTable';
import { OutgrowerContractsListResponse } from 'entities/Outgrower/sdk';

import { SwrMutator } from 'utils/sdk';
import { ListSortProps } from 'utils/sdk';

import ActiveOutgrowerContractsListTable from './ActiveOutgrowerContractsListTable';

interface OutgrowerContractsListTableProps {
  contracts: OutgrowerContractsListResponse['contracts'];
  outgrower: OutgrowerContractsListResponse['outgrower'];
  filters: ContractsListFiltersProps;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  contractsRefetch: SwrMutator;
  currency: string;
}

const OutgrowerContractsListTable = ({
  contracts,
  outgrower,
  filters,
  onSort,
  sort,
  contractsRefetch,
  currency
}: OutgrowerContractsListTableProps) => {
  const contractsFilteredByActive = filters.status === CONTRACT_STATUSES.ACTIVE;
  const contractsFilteredByUpcoming =
    filters.status === CONTRACT_STATUSES.UPCOMING;
  const contractsFilteredByClosed = filters.status === CONTRACT_STATUSES.CLOSED;

  if (contractsFilteredByActive) {
    return (
      <ActiveOutgrowerContractsListTable
        currency={currency}
        contracts={contracts}
        outgrower={outgrower}
        onSort={onSort}
        sort={sort}
        contractsRefetch={contractsRefetch}
        filters={filters}
      />
    );
  }

  if (contractsFilteredByUpcoming) {
    return (
      <UpcomingOutgrowerContractsListTable
        currency={currency}
        contracts={contracts}
        outgrower={outgrower}
        onSort={onSort}
        sort={sort}
        contractsRefetch={contractsRefetch}
        filters={filters}
      />
    );
  }

  if (contractsFilteredByClosed) {
    return (
      <ClosedOutgrowerContractsListTable
        currency={currency}
        contracts={contracts}
        outgrower={outgrower}
        onSort={onSort}
        sort={sort}
        filters={filters}
      />
    );
  }

  return <div />;
};

export default OutgrowerContractsListTable;

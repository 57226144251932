import { toNumber } from 'lodash';

// We format units like 'kg'/ 't' / 'ha' with commas to 1 decimal point
export const formatUnitAmount = (value: string) =>
  toNumber(toNumber(value).toFixed(1)).toLocaleString('en-US', {
    minimumFractionDigits: 1
  });

// We format money with commas to 2 decimal points
export const formatMoney = (value: string) =>
  toNumber(value).toLocaleString('en-US', { minimumFractionDigits: 2 });

// We format count with commas and no decimal points
export const formatCount = (value: number) =>
  Math.trunc(toNumber(value)).toLocaleString('en-US');

// We format size with commas and 2 decimal points
export const formatSize = (value: number) =>
  toNumber(value).toLocaleString('en-US', { minimumFractionDigits: 2 });

import { default as MuiDialog } from '@mui/material/Dialog';
import { default as MuiDialogContent } from '@mui/material/DialogContent';
import { WhiteCloseIcon } from 'assets';

import Card, { CardMedia } from 'components/Card';
import IconButton from 'components/IconButton';

interface ImageDialogProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageDialog = ({ imageUrl, onClose }: ImageDialogProps) => {
  return (
    <MuiDialog
      open
      classes={{
        root: 'p-4',
        paper: 'm-0, rounded-[12px] bg-none shadow-none'
      }}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={() => ({
          position: 'absolute',
          right: 15.8,
          top: 22,
          padding: 0,
          cursor: 'pointer',
          zIndex: 1
        })}
      >
        <WhiteCloseIcon />
      </IconButton>
      <MuiDialogContent
        classes={{
          root: 'rounded-[12px] bg-none p-0 border-0 shadow-none'
        }}
      >
        <Card
          sx={{
            width: 'auto',
            height: 'auto',
            borderRadius: '12px',
            position: 'relative'
          }}
        >
          <CardMedia
            sx={{
              objectFit: 'cover'
            }}
            component="img"
            image={imageUrl}
          />
        </Card>
      </MuiDialogContent>
    </MuiDialog>
  );
};

export default ImageDialog;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useMe } from 'entities/Auth/sdk';
import ContractDetails from 'entities/Contracts/components/ContractDetails';
import ContractGallery from 'entities/Contracts/components/ContractGallery';
import { useContractGallery } from 'entities/Contracts/sdk';
import LeadOutgrowerDetails from 'entities/Outgrower/components/LeadOutgrowerDetails';
import OutgrowerDetails from 'entities/Outgrower/components/OutgrowerDetails';
import OutgrowerFarmDetails from 'entities/Outgrower/components/OutgrowerFarmDetails';
import { useOutgrowerDetails, useOutgrowerFarms } from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerContractGallery = () => {
  const { setPageTitle, setRightSidebarItems } = useLayout();
  const { outgrowerId, contractId } = useParams();

  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetails({
    outgrowerId
  });

  const { data: outgrowerFarms, isLoading: farmsLoading } = useOutgrowerFarms({
    outgrowerId
  });

  const { data: contractGallery, isLoading: contractGalleryLoading } =
    useContractGallery({
      contractId: contractId
    });

  const { data: me } = useMe();

  useEffect(() => {
    if (!outgrower || !outgrowerFarms || !contractGallery || !me) return;

    setRightSidebarItems([
      <OutgrowerDetails outgrower={outgrower} />,
      outgrower.lead_outgrower && (
        <LeadOutgrowerDetails leadOutgrower={outgrower.lead_outgrower} />
      ),
      <OutgrowerFarmDetails farm={outgrowerFarms[0]} />,
      <ContractDetails contract={contractGallery} currency={me.currency} />
    ]);

    return () => setRightSidebarItems([]);
  }, [setRightSidebarItems, outgrower, outgrowerFarms, contractGallery, me]);

  useEffect(() => {
    setPageTitle(<PageTitle text="Gallery" />);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const loading = outgrowerLoading || farmsLoading || contractGalleryLoading;

  return (
    <div className="flex h-full flex-col bg-[#F8F8F8] px-5 py-5">
      {loading && <ContentLoading />}
      {!loading && <ContractGallery contractGallery={contractGallery} />}
    </div>
  );
};

export default OutgrowerContractGallery;

import { useState } from 'react';

import {
  ClearAllInGreenCircle,
  DynamicFeedInGreenCircleIcon,
  EditIcon,
  GrassInGreenIcon,
  LineChartInGreeenCircle
} from 'assets';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { twMerge } from 'tailwind-merge';

import ContractAnticipatedStageSelectDialog from 'entities/Contracts/components/ContractAnticipatedStageSelectDialog';
import {
  RAG_STATUS_COLOR_MAPPER,
  RAG_STATUS_COLOR_TO_TITLE_MAPPER
} from 'entities/Contracts/constants';
import { ContractDiaryProps } from 'entities/Contracts/sdk';

import CircularProgress from 'components/CircularProgress';
import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

import { SwrMutator } from 'utils/sdk';

dayjs.extend(relativeTime);

interface OutgrowerContractDiaryStatisticsProps {
  contractDiary: ContractDiaryProps;
  contractDiaryRefetch: SwrMutator;
}

const ActiveContractDiaryStatistics = ({
  contractDiary,
  contractDiaryRefetch
}: OutgrowerContractDiaryStatisticsProps) => {
  const ragStatusColor =
    RAG_STATUS_COLOR_MAPPER[
      contractDiary.rag_status_color as keyof typeof RAG_STATUS_COLOR_MAPPER
    ];
  const [
    anticipatedStageSelectDialogOpened,
    setAnticipatedStageSelectDialogOpened
  ] = useState(false);

  const handleDialogOpen = () => {
    setAnticipatedStageSelectDialogOpened(true);
  };

  const handleDialogClose = () => {
    setAnticipatedStageSelectDialogOpened(false);
  };

  const ragStatus =
    RAG_STATUS_COLOR_TO_TITLE_MAPPER[
      contractDiary.rag_status_color as keyof typeof RAG_STATUS_COLOR_TO_TITLE_MAPPER
    ];

  return (
    <div className="flex w-full gap-3">
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[220px] flex-[4] px-3 py-[10px]"
        icon={<ClearAllInGreenCircle />}
        title="RAG Status"
        value={
          <div className="flex h-full flex-col items-start gap-[2px]">
            <Tooltip title={contractDiary.rag_status_reason}>
              <Text
                className={twMerge(
                  'text-xl font-semibold leading-5 tracking-[0.15px]',
                  `text-[${ragStatusColor}]`
                )}
              >
                {ragStatus}
              </Text>
            </Tooltip>

            <div className="flex items-center whitespace-pre text-sm leading-[18px] text-wet-green">
              <div className="font-normal text-wet-green">Last action: </div>
              <div className="font-semibold text-wet-green">
                {contractDiary.last_action &&
                  dayjs(contractDiary.last_action).format('YYYY-MM-DD')}
                {!contractDiary.last_action && 'N/A'}
              </div>
            </div>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[80px] px-3 py-[10px]"
        icon={<GrassInGreenIcon />}
        title="Crop"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {contractDiary.crop}
            </Text>
            <Text
              color="wet-green"
              className="text-xs font-semibold uppercase leading-[18px] tracking-[0.15px] text-wet-green"
            >
              {contractDiary.crop_variety}
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[130px] flex-[3] px-3 py-[10px]"
        icon={<DynamicFeedInGreenCircleIcon />}
        title="Current Stage"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {contractDiary.current_stage || 'N/A'}
            </Text>
            <Text
              color="wet-green"
              className="flex items-center whitespace-pre text-sm leading-[18px]"
            >
              <div className="font-normal text-wet-green">Since: </div>
              <div className="font-semibold text-wet-green">
                {contractDiary.current_stage_updated_at &&
                  dayjs(contractDiary.current_stage_updated_at).format(
                    'YYYY-MM-DD'
                  )}

                {!contractDiary.current_stage_updated_at && 'N/A'}
              </div>
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[170px] flex-[5] px-3 py-[10px]"
        icon={<DynamicFeedInGreenCircleIcon />}
        title="Stage next week"
        actionIcon={<EditIcon />}
        action={handleDialogOpen}
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {contractDiary.anticipated_stage || 'N/A'}
            </Text>
            <Text
              color="wet-green"
              className="flex items-center whitespace-pre text-sm leading-[18px]"
            >
              <div className="font-normal text-wet-green">Updated by: </div>
              <div className="font-semibold text-wet-green">
                {contractDiary.anticipated_stage_updated_by || 'N/A'}
              </div>
              <div className="font-normal text-wet-green"> on </div>
              <div className="font-semibold text-wet-green">
                {contractDiary.anticipated_stage_updated_at &&
                  dayjs(contractDiary.anticipated_stage_updated_at).format(
                    'YYYY-MM-DD'
                  )}
                {!contractDiary.anticipated_stage_updated_at && 'N/A'}
              </div>
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-2"
        className="min-w-[175px] flex-[2] px-3 py-[10px]"
        icon={<LineChartInGreeenCircle />}
        title="Task Progress"
        value={
          <div className="flex h-full items-center gap-2">
            <div className="relative inline-flex">
              <CircularProgress
                variant="determinate"
                thickness={8}
                size={45}
                value={100}
                className="text-fresh-green"
              />
              <CircularProgress
                variant="determinate"
                thickness={8}
                size={45}
                value={contractDiary.task_progress}
                className="absolute left-0 text-wet-green"
                classes={{
                  circle: '[stroke-linecap:round]'
                }}
              />
            </div>
            <div className="flex items-center">
              <Text
                color="wet-green"
                className="text-[28px] font-semibold leading-7 tracking-[0.15px]"
              >
                {Math.round(contractDiary.task_progress)}
              </Text>
              <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-[#757575]">
                % sent
              </Text>
            </div>
          </div>
        }
      />
      {anticipatedStageSelectDialogOpened && (
        <ContractAnticipatedStageSelectDialog
          contractId={contractDiary.id}
          onClose={handleDialogClose}
          contractDiaryRefetch={contractDiaryRefetch}
        />
      )}
    </div>
  );
};

export default ActiveContractDiaryStatistics;

import { CONTRACT_TASK_STATUSES } from 'entities/Contracts/constants';

import Chip from 'components/Chip';

interface ContractTaskStatusProps {
  status: string;
}

const ContractTaskStatus = ({ status }: ContractTaskStatusProps) => {
  if (status === CONTRACT_TASK_STATUSES.PENDING) {
    return (
      <Chip
        label={'Pending'}
        classes={{
          root: 'bg-inherit  text-[#C77700] border-[#FFE0B2] border-solid border rounded-2xl px-[10px] py-1 font-medium leading-5 text-xs tracking-[0.4px] '
        }}
      />
    );
  }

  if (status === CONTRACT_TASK_STATUSES.SENT) {
    return (
      <Chip
        label={'Sent'}
        classes={{
          root: 'bg-inherit  text-[#f2cd3c] border-[#FFE789] border-solid border rounded-2xl px-[10px] py-1 font-medium leading-5 text-xs tracking-[0.4px] '
        }}
      />
    );
  }

  if (status === CONTRACT_TASK_STATUSES.UNDERSTOOD) {
    return (
      <Chip
        label={'Understood'}
        classes={{
          root: 'bg-inherit  text-[#3B873E] border-[#C8E6C9] border-solid border rounded-2xl px-[10px] py-1 font-medium leading-5 text-xs tracking-[0.4px] '
        }}
      />
    );
  }

  if (status === CONTRACT_TASK_STATUSES.NOT_UNDERSTOOD) {
    return (
      <Chip
        label={'Not understood'}
        classes={{
          root: 'bg-inherit  text-[#E31B0C] border-[#FECDD2] border-solid border rounded-2xl px-[10px] py-1 font-medium leading-5 text-xs tracking-[0.4px] '
        }}
      />
    );
  }

  return <Chip label={'Unknown'} />;
};

export default ContractTaskStatus;

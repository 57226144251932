import { useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import FarmInformation from 'entities/Outgrower/components/ProfileCreateForm/FarmInformation';
import OutgrowerInformation from 'entities/Outgrower/components/ProfileCreateForm/OutgrowerInformation';
import {
  CountryOption,
  OutgrowerCreateProps,
  OutgrowerListResponse,
  useCountriesList,
  useOutgrowersList
} from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useForm } from 'utils/forms';

import { URLS } from 'config/urls';

export interface FormProps {
  first_name: string;
  last_name: string;
  gender?: string;
  id_number?: string;
  birth_date?: dayjs.Dayjs;
  email: string;
  phone_number: string;
  company_name?: string;
  region?: string;
  street?: string;
  country: CountryOption;
  town: string;
  house_number?: string;
  farm_name?: string;
  farm_number?: string;
  farm_land_type: string;
  farm_soil_type: string;
  farm_irrigation: boolean;
  farm_region?: string;
  farm_street?: string;
  farm_size: number;
  farm_country: CountryOption;
  farm_town: string;
  farm_street_number?: string;
  is_lead: string;
  lead_outgrower?: { label: string; value: number };
}

const defaultValues = {
  is_lead: 'false'
};

interface IProfileCreateFormProps {
  onSave: (data: OutgrowerCreateProps) => Promise<any>;
}

const ProfileCreateForm = ({ onSave }: IProfileCreateFormProps) => {
  const navigate = useNavigate();

  const { data: outgrowers } = useOutgrowersList();

  const { data: countries } = useCountriesList();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue
  } = useForm<FormProps>({ defaultValues: defaultValues });

  const [blockNavigation, setBlockNavigation] = useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    // In case of inactivity, we don't want to block the navigation
    if (blockNavigation && nextLocation.pathname == URLS.LOGIN) {
      return false;
    }

    if (blockNavigation && nextLocation.pathname !== currentLocation.pathname) {
      setShowConfirmationDialog(true);
      return true;
    }
    return false;
  });

  const confirmNavigation = () => {
    setShowConfirmationDialog(false);
    if (blocker.state == 'blocked') {
      blocker.proceed();
    }
  };

  const cancelNavigation = () => {
    setShowConfirmationDialog(false);
    if (blocker.state == 'blocked') {
      blocker.reset();
    }
  };

  const onSubmit = (data: FormProps) => {
    setBlockNavigation(false);
    const submitData: OutgrowerCreateProps = {
      first_name: data.first_name,
      last_name: data.last_name,
      id_number: data.id_number || undefined,
      gender: data.gender || undefined,
      birth_date: data.birth_date
        ? dayjs(data.birth_date).format('YYYY-MM-DD')
        : undefined,
      email: data.email,
      phone_number: data.phone_number,
      company_name: data.company_name || undefined,
      region: data.region || undefined,
      street: data.street || undefined,
      country: data.country.value,
      town: data.town,
      house_number: data.house_number || undefined,
      is_lead: data.is_lead === 'true' ? true : false,
      lead_outgrower: data.lead_outgrower?.value || undefined,
      farms_data: [
        {
          name: data.farm_name || undefined,
          number: data.farm_number || undefined,
          ownership: data.farm_land_type,
          soil_type: data.farm_soil_type,
          irrigation: data.farm_irrigation,
          region: data.farm_region || undefined,
          street: data.farm_street || undefined,
          size: data.farm_size,
          country: data.farm_country.value,
          town: data.farm_town,
          street_number: data.farm_street_number || undefined
        }
      ]
    };

    onSave(submitData).catch(({ setFormErrors }) => setFormErrors(setError));
  };

  const leadOutgrowers =
    outgrowers &&
    outgrowers.filter((outgrower: OutgrowerListResponse) => outgrower.is_lead);

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        <div className="flex-1 flex-col overflow-y-auto px-[26px] py-5">
          <div className="flex gap-3">
            <OutgrowerInformation
              control={control}
              errors={errors}
              watch={watch}
              leadOutgrowers={leadOutgrowers}
              countries={countries || []}
              setValue={setValue}
            />

            <FarmInformation
              control={control}
              errors={errors}
              getValues={getValues}
              countries={countries || []}
            />
          </div>
        </div>

        <div className="flex h-[76px] items-end justify-end gap-4 p-4 shadow-[6px_0_4px_-1px_#D9D9D9]">
          <Button
            variant="outlined"
            color="wetGreen"
            className="w-[178px]"
            onClick={() => navigate(URLS.OUTGROWERS)}
          >
            Cancel
          </Button>
          <Button
            className="w-[178px]"
            type="submit"
            variant="contained"
            color="wetGreen"
          >
            Create Profile
          </Button>
        </div>
      </form>
      {showConfirmationDialog && (
        <ConfirmationDialog
          title={'Unsaved Changes'}
          message={
            'Are you sure you want to leave this page? Any unsaved data will be lost.'
          }
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
          confirmMessage={'Yes'}
          cancelMessage={'No'}
        />
      )}
    </div>
  );
};

export default ProfileCreateForm;

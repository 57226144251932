import { PhoneNumberUtil } from 'google-libphonenumber';

import PhoneInput from 'components/PhoneInput';
import { TextFieldProps } from 'components/TextField';

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'utils/forms';

interface PhoneFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  rules?: RegisterOptions<T>;
  fieldProps: TextFieldProps;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const phoneNumberValidate = (phone: string) => {
  try {
    const isPhoneValid = phoneUtil.isValidNumber(
      phoneUtil.parseAndKeepRawInput(phone)
    );

    if (!isPhoneValid) {
      return { isValid: false, message: 'Phone number is not valid' };
    }

    return { isValid: true, message: '' };
  } catch (error: any) {
    return { isValid: false, message: error.message };
  }
};

const PhoneField = <T extends FieldValues>({
  name,
  control,
  rules,
  fieldProps: { label, placeholder, error, helperText }
}: PhoneFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: (value) => {
          const phoneNumberValidator = phoneNumberValidate(value);
          if (!phoneNumberValidator.isValid) {
            return phoneNumberValidator.message;
          }

          return true;
        }
      }}
      render={({ field }) => (
        <PhoneInput
          value={field.value}
          onChange={field.onChange}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default PhoneField;

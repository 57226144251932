import { useEffect, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

import { PlusIcon } from 'assets';

import { useMe } from 'entities/Auth/sdk';
import ContractsChipListProps from 'entities/Contracts/components/ContractsChipListProps';
import ContractsListFilters from 'entities/Contracts/components/ContractsListFilters';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import OutgrowerContractsListTable from 'entities/Outgrower/components/OutgrowerContractsListTable';
import OutgrowerDetailsInContractsList from 'entities/Outgrower/components/OutgrowerDetailsInContractsList';
import {
  OutgrowerContractsListFiltersProps,
  useOutgrowerContractsList
} from 'entities/Outgrower/sdk';

import Button from 'components/Button';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

import { URLS } from 'config/urls';

const OutgrowerContracts = () => {
  const { setPageTitle, setActionButton } = useLayout();
  const { outgrowerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const contractStatus = location.state?.status || CONTRACT_STATUSES.ACTIVE;

  const initialFilters = location.state?.filters || {
    status: contractStatus
  };

  const [filters, setFilters] =
    useState<OutgrowerContractsListFiltersProps>(initialFilters);

  const initialSort = location.state?.sort || {
    sort: 'id',
    direction: 'asc'
  };

  const [sort, setSort] = useState<ListSortProps>(initialSort);

  const {
    data: outgrowerData,
    isLoading: outgrowerDataLoading,
    mutate: contractsRefetch
  } = useOutgrowerContractsList({
    outgrowerId,
    filters,
    sort: buildSort(sort)
  });

  useEffect(() => {
    setPageTitle(<PageTitle text="Contracts"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: me, isLoading: meLoading } = useMe();

  useEffect(() => {
    setActionButton(
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        color="wetGreen"
        onClick={() =>
          navigate(
            generatePath(URLS.OUTGROWER_CONTRACT_CREATE, {
              outgrowerId: outgrowerId as string
            })
          )
        }
      >
        Add Contract
      </Button>
    );

    return () => setActionButton(null);
  }, [setActionButton, navigate, outgrowerId]);

  return (
    <div className="flex h-full flex-col gap-5 p-5">
      <OutgrowerDetailsInContractsList
        outgrower={outgrowerData?.outgrower}
        isLoading={outgrowerDataLoading}
      />

      <div className="flex items-center justify-between">
        <ContractsChipListProps filters={filters} onFilter={setFilters} />
        <ContractsListFilters filters={filters} onFilter={setFilters} />
      </div>

      {(outgrowerDataLoading || meLoading) && <ContentLoading />}
      {!outgrowerDataLoading && outgrowerData && me && (
        <OutgrowerContractsListTable
          currency={me.currency}
          contracts={outgrowerData.contracts}
          outgrower={outgrowerData?.outgrower}
          filters={filters}
          onSort={setSort}
          sort={sort}
          contractsRefetch={contractsRefetch}
        />
      )}
    </div>
  );
};

export default OutgrowerContracts;

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

interface ConfirmationDialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmMessage: string;
  cancelMessage: string;
}

const ConfirmationDialog = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmMessage,
  cancelMessage
}: ConfirmationDialogProps) => {
  return (
    <Dialog title={title}>
      <div className="py-8">
        <Text className="pb-5 text-xl font-medium leading-[30px] tracking-[0.15px] text-wet-green">
          {message}
        </Text>
      </div>
      <div className="flex justify-center gap-3">
        <Button
          onClick={onConfirm}
          variant="outlined"
          className="font-medium"
          color="wetGreen"
        >
          {confirmMessage}
        </Button>
        <Button
          onClick={onCancel}
          variant="contained"
          className="font-medium"
          color="wetGreen"
        >
          {cancelMessage}
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;

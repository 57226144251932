import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { toNumber } from 'lodash';

import ContractCreateForm from 'entities/Contracts/components/ContractCreateForm';
import ContractCreatedDialog from 'entities/Contracts/components/ContractCreatedDialog';
import { ContractCreateProps, contractCreate } from 'entities/Contracts/sdk';
import { useCropChoicesList } from 'entities/Crops/sdk';
import LeadOutgrowerDetails from 'entities/Outgrower/components/LeadOutgrowerDetails';
import OutgrowerDetails from 'entities/Outgrower/components/OutgrowerDetails';
import OutgrowerFarmDetails from 'entities/Outgrower/components/OutgrowerFarmDetails';
import { useOutgrowerDetails, useOutgrowerFarms } from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const ContractsCreate = () => {
  const { setPageTitle, setRightSidebarItems } = useLayout();
  const { outgrowerId } = useParams();
  const { data: crops, isLoading: cropsLoading } = useCropChoicesList();

  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetails({
    outgrowerId
  });

  const { data: outgrowerFarms, isLoading: outgrowerFarmsLoading } =
    useOutgrowerFarms({
      outgrowerId
    });
  const [contractCreateDialogOpened, setContractCreatedDialogOpened] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle(<PageTitle text="Assign Contract"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle, navigate]);

  useEffect(() => {
    if (!outgrower || !outgrowerFarms) return;

    setRightSidebarItems([
      <OutgrowerDetails outgrower={outgrower} />,
      outgrower.lead_outgrower && (
        <LeadOutgrowerDetails leadOutgrower={outgrower.lead_outgrower} />
      ),
      <OutgrowerFarmDetails farm={outgrowerFarms[0]} />
    ]);

    return () => setRightSidebarItems([]);
  }, [setRightSidebarItems, outgrower, outgrowerFarms]);

  const handleSave = (data: ContractCreateProps) => {
    return contractCreate(data).then(() => {
      setContractCreatedDialogOpened(true);
    });
  };
  const loading = outgrowerLoading || cropsLoading || outgrowerFarmsLoading;

  return (
    <div className="flex h-full flex-col">
      {loading && <ContentLoading />}

      {crops && !cropsLoading && outgrowerFarms && (
        <ContractCreateForm
          onSave={handleSave}
          crops={crops}
          outgrowerId={toNumber(outgrowerId)}
          outgrowerFarms={outgrowerFarms}
        />
      )}
      {contractCreateDialogOpened && outgrowerId && (
        <ContractCreatedDialog outgrowerId={outgrowerId} />
      )}
    </div>
  );
};

export default ContractsCreate;

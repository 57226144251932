import { DownloadIcon } from 'assets';

import ContractTaskStatus from 'entities/Contracts/components/ContractTaskStatus';
import { ContractDiaryProps } from 'entities/Contracts/sdk';

import Card from 'components/Card';
import IconButton from 'components/IconButton';
import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';
import Text from 'components/Text';

import { ListSortProps } from 'utils/sdk';

interface ContractDiaryTableProps {
  contractDiary?: ContractDiaryProps;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
  contractDiaryWeekSelect: (task: ContractDiaryProps['weeks'][0]) => void;
}

const ContractDiaryTable = ({
  contractDiary,
  onSort,
  sort,
  contractDiaryWeekSelect
}: ContractDiaryTableProps) => {
  const tableIsEmpty = contractDiary?.weeks.length === 0;

  return (
    <Card
      classes={{
        root: 'flex h-full flex-col p-4 gap-3 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
      }}
    >
      <Text className="text-lg font-semibold leading-[28.8px] tracking-[0.15px] text-wet-green">
        Tasks
      </Text>
      <TableContainer
        className="h-full"
        classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
      >
        <Table
          stickyHeader
          classes={{
            root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
          }}
        >
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                content="Execution Week"
                onSort={onSort}
                sortBy={sort}
                sortName={'execution_week'}
              />
              <TableHeaderCell content="Task" />
              <TableHeaderCell content="Status" />
              <TableHeaderCell content="PDF" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableIsEmpty && (
              <TableEmptyState tableColumns={11} text={'No diary available'} />
            )}
            {contractDiary?.weeks.map((week) => (
              <TableRow
                hover
                key={week.execution_week}
                classes={{
                  root: 'odd:bg-white even:bg-[#b3cbbd]/10 cursor-pointer'
                }}
                onClick={() => contractDiaryWeekSelect(week)}
              >
                <TableBodyCell content={week.execution_week} />
                <TableBodyCell content={week.stage} />
                <TableBodyCell
                  content={<ContractTaskStatus status={week.status} />}
                />
                {week.pdf_link && (
                  <TableBodyCell
                    content={
                      <IconButton
                        aria-label="download"
                        href={week.pdf_link}
                        target="_blank"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                  />
                )}
                {!week.pdf_link && <TableBodyCell content={'N/A'} />}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ContractDiaryTable;

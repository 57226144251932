import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { default as Card, CardContent, CardProps } from 'components/Card';
import IconButton from 'components/IconButton';
import Text from 'components/Text';

interface StatisticsCardProps extends CardProps {
  icon: ReactNode;
  title: string;
  actionIcon?: ReactNode;
  action?: () => void;
  value: ReactNode;
  className?: string;
  cardContentClassName?: string;
}

const StatisticsCard = ({
  icon,
  title,
  actionIcon,
  action,
  value,
  className,
  cardContentClassName
}: StatisticsCardProps) => {
  return (
    <Card
      classes={{
        root: twMerge(
          'flex flex-col px-3 py-[10px] min-w-[184px] h-[92px] border border-solid border-[#D4DAE1] rounded-xl bg-[#b3cbbd33]',
          className
        )
      }}
    >
      <CardContent
        classes={{
          root: twMerge(
            'flex flex-col w-full h-full text-[#757575] gap-4',
            cardContentClassName
          )
        }}
      >
        <div className="flex flex-row gap-1">
          {icon}
          <Text classes={{ root: 'text-sm' }}>{title}</Text>
          {actionIcon && action && (
            <IconButton
              classes={{ root: 'ml-auto px-0 pl-1 py-0' }}
              onClick={action}
            >
              {actionIcon}
            </IconButton>
          )}
        </div>
        <div className="flex flex-row items-end gap-1">{value}</div>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;

import { useState } from 'react';

import { ErrorIcon } from 'assets';

import { ContractGalleryProps as GalleryProps } from 'entities/Contracts/sdk';

import Card from 'components/Card';
import ImageCard from 'components/ImageCard';
import ImageDialog from 'components/ImageDialog';
import Text from 'components/Text';

interface ContractGalleryProps {
  contractGallery?: GalleryProps;
}

const ContractGallery = ({ contractGallery }: ContractGalleryProps) => {
  const galleryIsEmpty = contractGallery?.image_attachments.length === 0;

  const [imageUrlOpened, setImageUrlOpened] = useState<string | null>(null);

  return (
    <>
      <Card
        classes={{
          root: 'flex  flex-wrap p-5 gap-3 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white overflow-y-auto'
        }}
      >
        {contractGallery?.image_attachments.map((image) => (
          <ImageCard
            className="cursor-pointer"
            key={image.id}
            alt={'Contract Image'}
            url={image.file_url}
            date={image.created_at}
            width="170px"
            height="170px"
            onClick={() => setImageUrlOpened(image.file_url)}
          />
        ))}
        {galleryIsEmpty && (
          <div className="flex h-[620px] w-full flex-col items-center justify-center gap-2">
            <ErrorIcon />
            <Text
              color="wet-green"
              className="text-sm font-semibold leading-[30px] tracking-[0.15px]"
            >
              No photos available
            </Text>
          </div>
        )}
      </Card>
      {imageUrlOpened && (
        <ImageDialog
          imageUrl={imageUrlOpened}
          onClose={() => setImageUrlOpened(null)}
        />
      )}
    </>
  );
};

export default ContractGallery;

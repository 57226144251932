import { ClearAllInGreenCircle, GrassInGreenIcon } from 'assets';
import { capitalize } from 'lodash';

import { ContractDiaryProps } from 'entities/Contracts/sdk';

import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';

import { formatUnitAmount } from 'utils/numbers';

interface OutgrowerContractDiaryStatisticsProps {
  contractDiary: ContractDiaryProps;
}

const ClosedContractDiaryStatistics = ({
  contractDiary
}: OutgrowerContractDiaryStatisticsProps) => {
  return (
    <div className="flex gap-3">
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[305px] flex-[1] px-3 py-[10px]"
        icon={<ClearAllInGreenCircle />}
        title="Status"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-[#E31B0C]">
              {capitalize(contractDiary.status)}
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="flex-[1] px-3 py-[10px]"
        icon={<GrassInGreenIcon />}
        title="Crop"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {contractDiary.crop}
            </Text>
            <Text
              color="wet-green"
              className="text-xs font-semibold uppercase leading-[18px] tracking-[0.15px] text-wet-green"
            >
              {contractDiary.crop_variety}
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[305px] flex-[1] px-3 py-[10px]"
        icon={<GrassInGreenIcon />}
        title="Harvest amount"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {formatUnitAmount(contractDiary.harvest_amount)} t
            </Text>
          </div>
        }
      />
    </div>
  );
};

export default ClosedContractDiaryStatistics;

import { useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from 'assets';
import { get } from 'lodash';

import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import AutoComplete from 'components/AutoComplete';
import Card from 'components/Card';
import ClickAwayListener from 'components/ClickAwayListener';
import Text from 'components/Text';
import TextField from 'components/TextField';

interface Option {
  label: string;
  value: number | string;
}

interface DashboardFilterProps {
  label: string;
  filterName: string;
  filters: DashboardFiltersProps;
  onFilter: (filters: DashboardFiltersProps) => void;
  filterOptions: Array<Option>;
  isOpen: boolean;
  openFilter: () => void;
  closeFilter: () => void;
}

const DashboardFilter = ({
  filters,
  filterName,
  label,
  onFilter,
  filterOptions,
  isOpen,
  openFilter,
  closeFilter
}: DashboardFilterProps) => {
  const optionFromFilters = filterOptions.find(
    (option) => option.value === get(filters, filterName)
  );

  const initialSelectedOption = filters ? optionFromFilters : undefined;

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  useEffect(() => {
    if (optionFromFilters === undefined && selectedOption !== undefined) {
      setSelectedOption(undefined);
    }
  }, [optionFromFilters, selectedOption]);

  const onAutoCompleteChange = (_: any, option: Option | null) => {
    setSelectedOption(option || undefined);
    onFilter({ ...filters, [filterName]: option?.value, crop: filters.crop });
    closeFilter();
  };

  return (
    <div className="relative flex flex-col">
      <div
        className="flex h-8 cursor-pointer items-center justify-start gap-1 rounded-2xl border border-solid border-[#d0d5dd] bg-white px-3.5 py-2.5 shadow-[0_1px_2px_0px_#1018280d]"
        onClick={openFilter}
      >
        <Text className="font-['Inter'] text-[13px] font-semibold leading-[19.5px] text-[#757575]">
          {label}:
        </Text>
        <Text
          color="wet-green"
          className="font-['Inter'] text-[13px] font-semibold leading-[19.5px]"
        >
          {optionFromFilters?.label || 'All'}
        </Text>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={closeFilter}>
          <Card className="absolute left-0 top-10 z-10 flex w-[250px] flex-col rounded-lg px-3 py-4 shadow-[4px_8px_17.8px_10px_#0000000f,4px_4px_4px_0px_#0000000f,0px_2px_0px_-1px_#EEE]">
            <AutoComplete
              value={selectedOption}
              options={filterOptions}
              onChange={onAutoCompleteChange}
              renderInput={(params) => (
                <TextField placeholder="Search" {...params} />
              )}
              slotProps={{
                paper: {
                  classes: {
                    root: 'shadow-none mt-[10px]'
                  }
                }
              }}
              ListboxProps={{ style: { maxHeight: '200px' } }}
            />
          </Card>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DashboardFilter;

import dayjs from 'dayjs';

import Card, { CardMedia } from 'components/Card';
import Chip from 'components/Chip';

interface ImageCardProps {
  url: string;
  date: string;
  alt: string;
  width: string;
  height: string;
  onClick?: () => void;
  className?: string;
}

const ImageCard = ({
  url,
  date,
  alt,
  width,
  height,
  onClick,
  className
}: ImageCardProps) => {
  const formattedDate = dayjs(date).format('DD.MM.YYYY');
  return (
    <Card
      sx={{ width, height, borderRadius: '8px', position: 'relative' }}
      classes={{
        root: className
      }}
      className={className}
      onClick={onClick}
    >
      <CardMedia
        sx={{
          width,
          height,
          objectFit: 'cover'
        }}
        component="img"
        image={url}
        alt={alt}
      ></CardMedia>
      <Chip
        className="absolute left-[6px] top-[6px] h-[18px] rounded-xl bg-[#fffc] px-2 text-xs font-medium leading-5 tracking-[0.311px] text-wet-green"
        label={formattedDate}
      />
    </Card>
  );
};

export default ImageCard;

import { BASE_URL, SortRequestProps, post, useFetch } from 'utils/sdk';

export interface ContractsDashboardListResponse {
  id: number;
  farm_name: string;
  farm_country: string;
  farm_town: string;
  land_size: number;
  outgrower_id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  lead_outgrower_first_name: string | null;
  lead_outgrower_last_name: string | null;
  crop: string;
  expected_harvest_amount: string;
  exposure: string;
}

export interface ContractListResponse {
  id: number;
  outgrower_id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  crop: string;
  execution_start_date: string;
  expected_harverst_date: string;
  expected_harvest_amount: string;
  actual_harvest_amount: string;
  actual_harvest_date: string;
  expected_farmgate_price: string;
  exposure: string;
  termination_reason: string | null;
  land_size: string;
}

export interface FertilizerSummaryProps {
  name: string;
  amount: string;
  unit: string;
  value: string;
}

export interface ChemicalSummaryProps {
  name: string;
  amount: string;
  unit: string;
  value: string;
}

export interface ContractSummaryPreviewResponse {
  plant_material_amount: string;
  plant_material_unit: string;
  plant_material_value: string;
  fertilizers: Array<FertilizerSummaryProps>;
  chemicals: Array<ChemicalSummaryProps>;
  yield_per_ha: string;
  total_yield: string;
  price_per_ton: string;
  total_amount: string;
  revenue: string;
}

export interface ContractSummaryPreviewProps {
  outgrower_id: number;
  crop_id: number;
  crop_variety_id: number;
  farm_id: number;
  land_size: number;
  signature_date: string;
  execution_start_date: string;
  expected_harverst_date: string;
}

export interface ContractsListFiltersProps {
  status: string;
  search?: string;
}

export interface ContractTerminateProps {
  reason: string;
}

export interface ContractEndProps {
  contractId: string;
}

export interface ContractHarvestRecordProps {
  harvest_date: string;
  harvest_amount: number;
}

export const useContractsList = ({
  filters,
  sort
}: {
  filters: ContractsListFiltersProps;
  sort: SortRequestProps;
}) =>
  useFetch<Array<ContractListResponse>>(`/api/contracts/`, {
    ...filters,
    ...sort
  });

export const contractSummaryPreview = (data: ContractSummaryPreviewProps) =>
  post<ContractSummaryPreviewResponse>(
    `${BASE_URL}/api/contracts/summary/preview/`,
    data
  );

export interface ContractCreateProps {
  outgrower_id: number;
  crop_id: number;
  crop_variety_id: number;
  farm_id: number;
  land_size: number;
  signature_date: string;
  execution_start_date: string;
  expected_harverst_date: string;
}

export const contractCreate = (data: ContractCreateProps) =>
  post(`${BASE_URL}/api/contracts/create/`, data);

export const contractTerminate = ({
  contractId,
  data
}: {
  contractId: string;
  data: ContractTerminateProps;
}) => post(`${BASE_URL}/api/contracts/${contractId}/terminate/`, data);

export const contractEnd = (contractId: string) =>
  post(`${BASE_URL}/api/contracts/${contractId}/end/`);

export const contractHarvestRecordCreate = ({
  contractId,
  data
}: {
  contractId: string;
  data: ContractHarvestRecordProps;
}) =>
  post(`${BASE_URL}/api/contracts/${contractId}/harvest-record/create/`, data);

export interface ContractDiaryProps {
  id: number;
  land_size: number;
  execution_start_date: string;
  expected_harverst_date: string;
  expected_farmgate_price: string;
  crop: string;
  crop_variety: string;
  status: string;
  rag_status_reason: string;
  rag_status_color: string;
  last_action: string;
  current_stage: string;
  current_stage_updated_at: string;
  anticipated_stage: string;
  anticipated_stage_updated_by: string;
  anticipated_stage_updated_at: string;
  task_progress: number;
  harvest_amount: string;
  weeks: Array<{
    execution_week: string;
    stage: string;
    status: string;
    pdf_link: string;
    tasks: Array<{
      id: number;
      number: number;
      description: string;
      considerations: string;
      headline: string;
    }>;
  }>;
  image_attachments: Array<{
    id: number;
    file_url: string;
    created_at: string;
  }>;
}
export const useContractDiary = ({
  contractId,
  sort
}: {
  contractId?: string;
  sort: SortRequestProps;
}) =>
  useFetch<ContractDiaryProps>(`/api/contracts/${contractId}/diary/`, {
    ...sort
  });

export interface ContractGalleryProps {
  id: number;
  land_size: number;
  execution_start_date: string;
  expected_harverst_date: string;
  expected_farmgate_price: string;
  crop: string;
  crop_variety: string;
  image_attachments: Array<{
    id: number;
    file_url: string;
    created_at: string;
  }>;
}
export const useContractGallery = ({ contractId }: { contractId?: string }) =>
  useFetch<ContractGalleryProps>(`/api/contracts/${contractId}/gallery/`);

export interface ContractAnticipatedStageOptionsProps {
  id: number;
  stage: string;
}

export const useContractAnticipatedStageOptions = ({
  contractId
}: {
  contractId?: string;
}) =>
  useFetch<Array<ContractAnticipatedStageOptionsProps>>(
    `/api/contracts/${contractId}/anticipated-stage-options/`
  );

export interface ContractAnticipatedStageUpdateProps {
  stage_id: number;
}

export const contractAnticipatedStageUpdate = ({
  contractId,
  data
}: {
  contractId: string;
  data: ContractAnticipatedStageUpdateProps;
}) =>
  post(
    `${BASE_URL}/api/contracts/${contractId}/anticipated-stage/update/`,
    data
  );

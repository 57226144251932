export const SOIL_TYPE_OPTIONS = [
  { label: 'Sand', value: 'sand' },
  { label: 'Loamy sand', value: 'loamy_sand' },
  { label: 'Sandy loam', value: 'sandy_loam' },
  { label: 'Loam', value: 'loam' },
  { label: 'Silt loam', value: 'silt_loam' },
  { label: 'Silt', value: 'silt' },
  { label: 'Sandy clay loam', value: 'sandy_clay_loam' },
  { label: 'Clay loam', value: 'clay_loam' },
  { label: 'Silty clay loam', value: 'silty_clay_loam' },
  { label: 'Sandy clay', value: 'sandy_clay' },
  { label: 'Silty clay', value: 'silty_clay' },
  { label: 'Clay', value: 'clay' }
];

export const IRRIGATION_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

export const LAND_TYPE_OPTIONS = [
  { label: 'Leased', value: 'leased' },
  { label: 'Owned', value: 'owned' }
];

export const LEAD_FARMER_OPTIONS = [
  { label: 'Yes', value: 'true' }, // The RadioGroup component accepts strings as option values
  { label: 'No', value: 'false' }
];

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' }
];

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BackArrowIcon } from 'assets';

import { useMe } from 'entities/Auth/sdk';
import ContractDetails from 'entities/Contracts/components/ContractDetails';
import ContractDiaryGallery from 'entities/Contracts/components/ContractDiaryGallery';
import ContractDiaryStatistics from 'entities/Contracts/components/ContractDiaryStatistics';
import ContractDiaryTable from 'entities/Contracts/components/ContractDiaryTable';
import ContractDiaryWeekDialog from 'entities/Contracts/components/ContractDiaryWeekDialog';
import { ContractDiaryProps, useContractDiary } from 'entities/Contracts/sdk';
import LeadOutgrowerDetails from 'entities/Outgrower/components/LeadOutgrowerDetails';
import OutgrowerDetails from 'entities/Outgrower/components/OutgrowerDetails';
import OutgrowerFarmDetails from 'entities/Outgrower/components/OutgrowerFarmDetails';
import { useOutgrowerDetails, useOutgrowerFarms } from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

const OutgrowerContractDiary = () => {
  const navigate = useNavigate();

  const { setPageTitle, setRightSidebarItems } = useLayout();
  const { outgrowerId, contractId } = useParams();
  const location = useLocation();
  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetails({
    outgrowerId
  });

  const { data: outgrowerFarms, isLoading: farmsLoading } = useOutgrowerFarms({
    outgrowerId
  });

  const [sort, setSort] = useState<ListSortProps>({
    sort: 'execution_week',
    direction: 'asc'
  });

  const {
    data: contractDiary,
    isLoading: contractDiaryLoading,
    mutate: contractDiaryRefetch
  } = useContractDiary({
    sort: buildSort(sort),
    contractId: contractId
  });

  const { data: me } = useMe();

  useEffect(() => {
    setPageTitle(
      <PageTitle
        icon={
          <BackArrowIcon
            className="cursor-pointer"
            onClick={() =>
              navigate(location.state?.url, {
                state: {
                  filters: location.state?.filters,
                  sort: location.state?.sort
                }
              })
            }
          />
        }
        text="Contract Diary Manager"
      />
    );

    return () => setPageTitle(null);
  }, [
    setPageTitle,
    navigate,
    location.state?.url,
    location.state?.filters,
    location.state?.sort
  ]);

  useEffect(() => {
    if (!outgrower || !outgrowerFarms || !contractDiary || !me) return;

    setRightSidebarItems([
      <OutgrowerDetails outgrower={outgrower} />,
      outgrower.lead_outgrower && (
        <LeadOutgrowerDetails leadOutgrower={outgrower.lead_outgrower} />
      ),
      <OutgrowerFarmDetails farm={outgrowerFarms[0]} />,
      <ContractDetails contract={contractDiary} currency={me?.currency} />
    ]);

    return () => setRightSidebarItems([]);
  }, [setRightSidebarItems, outgrower, outgrowerFarms, contractDiary, me]);

  const [selectedContractDiaryWeek, setSelectedContractDiaryWeek] = useState<
    ContractDiaryProps['weeks'][0] | undefined
  >();

  const loading = outgrowerLoading || farmsLoading || contractDiaryLoading;

  return (
    <div className="flex h-full flex-col px-5 py-5">
      <div className="flex h-full flex-col gap-3">
        <ContractDiaryStatistics
          contractDiaryRefetch={contractDiaryRefetch}
          contractDiary={contractDiary}
          contractDiaryLoading={loading}
        />
        {loading && <ContentLoading />}
        {!loading && (
          <>
            <ContractDiaryGallery
              contractId={contractId}
              outgrowerId={outgrowerId}
              contractDiary={contractDiary}
            />

            <ContractDiaryTable
              sort={sort}
              onSort={setSort}
              contractDiary={contractDiary}
              contractDiaryWeekSelect={setSelectedContractDiaryWeek}
            />
          </>
        )}

        {selectedContractDiaryWeek && (
          <ContractDiaryWeekDialog
            week={selectedContractDiaryWeek}
            onClose={() => setSelectedContractDiaryWeek(undefined)}
          />
        )}
      </div>
    </div>
  );
};

export default OutgrowerContractDiary;

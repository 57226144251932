import Text from 'components/Text';

const PrivacyPolicy = () => {
  return (
    <div className="p-6">
      <Text className="font-bold">
        Privacy Policy for WhatsApp API Integration
      </Text>
      <Text className="font-bold">Effective Date: 18.02.2025</Text>
      <Text className="font-bold">1. Introduction </Text>
      <Text>
        This Privacy Policy explains how sproot AG ("we", "us", "our") collects,
        uses, and protects the personal data of users interacting with our
        services through the WhatsApp Business API. By using our WhatsApp
        services, you agree to the terms outlined in this policy.
      </Text>
      <Text className="font-bold">2. Information We Collect </Text>
      <Text>
        When you interact with our WhatsApp services, we may collect the
        following information:{' '}
      </Text>
      <Text>
        <span className="font-bold">• User-Provided Data: </span> Messages,
        media files, and any other data you voluntarily send via WhatsApp.
      </Text>
      <Text>
        <span className="font-bold">• Device & Usage Data:</span> WhatsApp phone
        number, interaction history, timestamps, and metadata.
      </Text>
      <span className="font-bold">• Automated Data Collection:</span> Logs of
      interactions, including chatbot responses, error reports, and performance
      analytics.
      <Text className="font-bold">3. How We Use Your Information</Text>
      <Text>We collect and process data to:</Text>
      <Text>• Provide, maintain, and improve our WhatsApp-based services.</Text>
      <Text>• Automate responses and facilitate customer support.</Text>
      <Text>• Monitor system performance and prevent abuse.</Text>
      <Text>• Comply with legal obligations and industry standards. </Text>
      <Text className="font-bold">4. Data Sharing & Third-Party Services</Text>
      <Text>
        We do not sell, trade, or rent your personal data. However, we may share
        information with:
      </Text>
      <Text>
        <span className="font-bold">• WhatsApp & Meta Platforms: </span> As
        required for message delivery and API integration.
      </Text>
      <Text>
        <span className="font-bold">• Service Providers: </span>Companies
        assisting with hosting, analytics, and security.
      </Text>
      <Text>
        <span className="font-bold">• Legal Authorities: </span>If required by
        law, regulation, or legal process.
      </Text>
      <Text className="font-bold">5. Data Security</Text>
      <Text>We implement security measures, including:</Text>
      <Text>
        • End-to-end encryption (WhatsApp's default security feature).
      </Text>
      <Text>•Secure storage and restricted access to personal data.</Text>
      <Text>• Regular system monitoring to prevent unauthorized access.</Text>
      <Text className="font-bold">6. Data Retention</Text>
      We retain messages and related metadata only as long as necessary to
      provide the service. Message content is not stored indefinitely unless
      required for service continuity or compliance.
      <Text className="font-bold">7. User Rights</Text>
      <Text>You have rights to:</Text>
      <Text>• Access your data and request corrections.</Text>
      <Text>• Request deletion of personal data where applicable.</Text>
      <Text>• Withdraw consent for data processing.</Text>
      <Text>To exercise these rights, contact us at info@sproot.com.</Text>
      <Text className="font-bold">8. Changes to This Privacy Policy</Text>
      <Text>
        We may update this policy from time to time. Any significant changes
        will be communicated via WhatsApp or on our website.
      </Text>
      <Text className="font-bold">9. Contact Us</Text>
      <Text>
        For questions regarding this policy, contact us at: sproot AG Maienmatt
        8, 6315 Oberägeri, Switzerland
      </Text>
      <Text>info@sproot.com</Text>
      <Text>+41 77 286 54 79</Text>
    </div>
  );
};

export default PrivacyPolicy;

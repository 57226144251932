import { TractorIcon } from 'assets';

import { SOIL_TYPE_OPTIONS } from 'entities/Outgrower/constants';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import Text from 'components/Text';

import { formatSize } from 'utils/numbers';

interface OutgrowerFarmDetailsProps {
  farm: OutgrowerFarmResponse;
}

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => (
  <div className="text-xs font-normal leading-[17px] text-wet-green">
    {children}
  </div>
);

interface ValueProps {
  children: React.ReactNode;
}

const Value = ({ children }: ValueProps) => (
  <Text
    color="wet-green"
    className="text-right text-sm font-semibold tracking-[0.15px]"
  >
    {children}
  </Text>
);

const OutgrowerFarmDetails = ({ farm }: OutgrowerFarmDetailsProps) => {
  const soilType = SOIL_TYPE_OPTIONS.find(
    (option) => option.value === farm.soil_type
  )?.label;

  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-2">
      <div className="flex items-center gap-1">
        <TractorIcon />
        <Text
          color="wet-green"
          fontFamily={['DM Sans']}
          className="text-base leading-6"
        >
          Farm Details
        </Text>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Label>Name</Label>
          <Value>{farm.name || '-'}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Number</Label>
          <Value>{farm.number || '-'}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Farm size</Label>
          <Value>{formatSize(farm.size)} ha</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Soil type</Label>
          <Value>{soilType || '-'}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Irrigation</Label>
          <Value>
            {farm.irrigation === null ? '-' : farm.irrigation ? 'Yes' : 'No'}{' '}
          </Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Town</Label>
          <Value>{farm.town}</Value>
        </div>
        <div className="flex items-center justify-between">
          <Label>Country</Label>
          <Value>{farm.country}</Value>
        </div>
      </div>
    </div>
  );
};

export default OutgrowerFarmDetails;

import { ContractDiaryProps } from 'entities/Contracts/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

interface ContractDiaryWeekDialogProps {
  week: ContractDiaryProps['weeks'][0];
  onClose: () => void;
}

const ContractDiaryWeekDialog = ({
  week,
  onClose
}: ContractDiaryWeekDialogProps) => {
  return (
    <Dialog onClose={onClose} title={`Tasks for week ${week.execution_week}`}>
      <div className="py-8">
        {week.tasks.map((task) => (
          <div className="bg-[#DBFCB9] pb-5">
            <Text className="text-lg font-semibold">
              {task.number}. {task.headline}
            </Text>
            <Text>{task.description}</Text>
            {task.considerations && (
              <div>
                <Text className="pt-2 font-semibold">Considerations:</Text>
                <Text sx={{ whiteSpace: 'pre-line' }}>
                  {task.considerations}
                </Text>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center gap-3">
        <Button
          onClick={onClose}
          variant="contained"
          className="font-medium"
          color="wetGreen"
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default ContractDiaryWeekDialog;

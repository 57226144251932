import { useForm } from 'react-hook-form';

import {
  ContractTerminateProps,
  contractTerminate
} from 'entities/Contracts/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FormTextField } from 'components/Form';
import Text from 'components/Text';

interface ContractCancelDialogProps {
  contractId: number;
  onClose: () => void;
}

const ContractCancelDialog = ({
  contractId,
  onClose
}: ContractCancelDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ContractTerminateProps>();

  const onSubmit = (data: ContractTerminateProps) => {
    return contractTerminate({
      contractId: contractId.toString(),
      data: data
    }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog onClose={onClose} title="Cancel Contract">
      <div className="flex flex-col pt-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col gap-5"
        >
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-medium leading-[30px] tracking-[0.15px] text-wet-green">
              Reason for cancellation:
            </Text>
            <FormTextField
              name="reason"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                label: 'Reason',
                placeholder: 'Specify reason for cancellation',
                error: !!errors.reason,
                helperText: errors.reason?.message,
                multiline: true,
                rows: 4,
                sx: {
                  '& .MuiOutlinedInput-root': {
                    padding: '10px 12px',
                    height: '128px',
                    width: '460px'
                  }
                }
              }}
            />
          </div>
          <div className="flex justify-center gap-3">
            <Button
              variant="outlined"
              className="font-medium"
              color="wetGreen"
              onClick={onClose}
            >
              Discard
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="font-medium"
              color="wetGreen"
            >
              Cancel contract
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContractCancelDialog;

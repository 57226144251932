export const CONTRACT_STATUSES = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  UPCOMING: 'upcoming'
};

export const CONTRACT_TASK_STATUSES = {
  PENDING: 'pending',
  SENT: 'sent',
  UNDERSTOOD: 'understood',
  NOT_UNDERSTOOD: 'not_understood'
};

export const RAG_STATUS_COLORS = {
  GREEN: 'green',
  AMBER: 'amber',
  RED: 'red'
};

export const RAG_STATUS_COLOR_TO_ICON_MAPPER = {
  [RAG_STATUS_COLORS.GREEN]: '✅',
  [RAG_STATUS_COLORS.AMBER]: '⚠️',
  [RAG_STATUS_COLORS.RED]: '❗'
};

export const RAG_STATUS_COLOR_MAPPER = {
  [RAG_STATUS_COLORS.GREEN]: '#3B873E',
  [RAG_STATUS_COLORS.AMBER]: '#C77700',
  [RAG_STATUS_COLORS.RED]: '#E31B0C'
};

export const RAG_STATUS_COLOR_TO_TITLE_MAPPER = {
  [RAG_STATUS_COLORS.GREEN]: 'Up to Date ✅',
  [RAG_STATUS_COLORS.AMBER]: 'Check-in Needed ⚠️',
  [RAG_STATUS_COLORS.RED]: 'Immediate Action ❗'
};

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMe } from 'entities/Auth/sdk';
import DashboardListTable from 'entities/Contracts/components/DashboardListTable';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import { useCropChoicesForCompanyList } from 'entities/Crops/sdk';
import CropChipList from 'entities/Dashboard/components/CropChipList';
import CropChipListLoading from 'entities/Dashboard/components/CropChipListLoading';
import DashboardFilters from 'entities/Dashboard/components/DashboardFilters';
import DashboardStatistics from 'entities/Dashboard/components/DashboardStatistics';
import DashboardStatisticsLoading from 'entities/Dashboard/components/DashboardStatisticsLoading';
import { useContractsDashboardList } from 'entities/Dashboard/sdk';
import { useDashboardStatistics } from 'entities/Dashboard/sdk';
import { DashboardFiltersProps } from 'entities/Dashboard/sdk';

import Card from 'components/Card';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

const Dashboard = () => {
  const location = useLocation();
  const { setPageTitle } = useLayout();

  const initialSort = location.state?.sort || {
    sort: 'id',
    direction: 'asc'
  };

  const [sort, setSort] = useState<ListSortProps>(initialSort);

  useEffect(() => {
    setPageTitle(<PageTitle text="Dashboard"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: crops, isLoading: cropsLoading } =
    useCropChoicesForCompanyList();

  useEffect(() => {
    if (crops) {
      const defaultCrop = crops[0] ?? null;
      setFilters((prevFilters) => ({
        crop: defaultCrop?.crop_id,
        ...prevFilters
      }));
    }
  }, [crops]);

  const initialFilters = location.state?.filters || {
    status: CONTRACT_STATUSES.ACTIVE
  };

  const [filters, setFilters] = useState<DashboardFiltersProps>(initialFilters);

  const { data: contracts, isLoading: contractsLoading } =
    useContractsDashboardList({
      filters,
      sort: buildSort(sort)
    });

  const { data: contractsForOutgrowerFilter } = useContractsDashboardList({});

  const { data: statistics, isLoading: statisticsLoading } =
    useDashboardStatistics({
      filters,
      sort: buildSort(sort)
    });

  const { data: me, isLoading: meLoading } = useMe();

  const resetSort = () => {
    setSort(initialSort);
  };

  return (
    <div className="flex h-full flex-col gap-3 bg-[#F8F8F8] px-[26px] py-5">
      {!cropsLoading && crops && (
        <CropChipList crops={crops} onFilter={setFilters} filters={filters} />
      )}
      {cropsLoading && <CropChipListLoading />}
      {(statisticsLoading || meLoading) && <DashboardStatisticsLoading />}
      {!statisticsLoading && !meLoading && statistics && me && (
        <DashboardStatistics statistics={statistics} currency={me.currency} />
      )}
      <Card
        classes={{
          root: 'flex flex-col h-full w-full gap-5 p-5 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] '
        }}
      >
        <DashboardFilters
          filters={filters}
          contracts={contractsForOutgrowerFilter}
          onFilter={setFilters}
          resetSort={resetSort}
        />

        {(contractsLoading || meLoading) && <ContentLoading />}
        {!contractsLoading && !meLoading && contracts && me && (
          <DashboardListTable
            currency={me.currency}
            contracts={contracts}
            onSort={setSort}
            sort={sort}
            filters={filters}
          />
        )}
      </Card>
    </div>
  );
};

export default Dashboard;

import { UseFormGetValues } from 'react-hook-form';

import { TractorIcon } from 'assets';

import { FormProps } from 'entities/Outgrower/components/ProfileUpdateForm';
import {
  IRRIGATION_OPTIONS,
  LAND_TYPE_OPTIONS,
  SOIL_TYPE_OPTIONS
} from 'entities/Outgrower/constants';
import { CountryOption } from 'entities/Outgrower/sdk';

import { FormSelectField, FormTextField } from 'components/Form';
import FormCountryField from 'components/Form/CountryField';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';

import { Control, FieldErrors } from 'utils/forms';

interface FarmInformationProps {
  control: Control<FormProps>;
  errors: FieldErrors<FormProps>;
  getValues: UseFormGetValues<FormProps>;
  countries: Array<CountryOption>;
}

const FarmInformation = ({
  control,
  errors,
  getValues,
  countries
}: FarmInformationProps) => {
  const checkFarmHasNameOrNumber = () => {
    const farmName = getValues('farm_name');
    const farmNumber = getValues('farm_number');

    return farmName || farmNumber
      ? true
      : 'Farm requires either a name or a number';
  };
  return (
    <div className="flex flex-1 flex-col gap-3">
      <Text
        classes={{
          root: 'text-xl font-semibold  text-wet-green leading-8 tracking-[0.15px]'
        }}
      >
        Farm Information
      </Text>
      <div className="flex flex-col gap-6 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <TractorIcon />
          <Text className="font-base font-semibold leading-[19.2px]">Farm</Text>
        </div>
        <div className="flex gap-3">
          <div className="flex w-1/2 flex-col gap-4">
            <FormTextField
              name="farm_name"
              control={control}
              rules={{ validate: checkFarmHasNameOrNumber }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 11
                  }
                },
                label: 'Farm name',
                error: !!errors.farm_name,
                helperText: errors.farm_name?.message
              }}
            />
            <FormTextField
              name="farm_size"
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^\d*\.?\d{0,2}$/, // Allows up to 2 decimal places
                  message: 'Only up to two decimal places are allowed'
                }
              }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 12
                  }
                },
                type: 'number',
                label: 'Farm size (ha)*',
                error: !!errors.farm_size,
                helperText: errors.farm_size?.message
              }}
            />
            <FormSelectField
              name="farm_soil_type"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 14
                  }
                },
                id: 'farm_soil_type"',
                label: 'Soil type',
                select: true,
                error: !!errors.farm_soil_type,
                helperText: errors.farm_soil_type?.message
              }}
            >
              {SOIL_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>
            <FormCountryField
              countries={countries}
              control={control}
              fieldProps={{
                name: 'farm_country',
                label: 'Country*',
                error: !!errors.farm_country,
                helperText: errors.farm_country?.message
              }}
            />
            <FormTextField
              name="farm_town"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 18
                  }
                },
                label: 'Town*',
                error: !!errors.farm_town,
                helperText: errors.farm_town?.message
              }}
            />
            <FormTextField
              name="farm_street_number"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 20
                  }
                },
                type: 'text',
                label: 'Street number',
                error: !!errors.farm_street_number,
                helperText: errors.farm_street_number?.message
              }}
            />
          </div>
          <div className="flex w-1/2 flex-col gap-4">
            <FormTextField
              name="farm_number"
              control={control}
              rules={{ validate: checkFarmHasNameOrNumber }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 11
                  }
                },
                label: 'Farm number',
                error: !!errors.farm_number,
                helperText: errors.farm_number?.message
              }}
            />
            <FormSelectField
              name="farm_land_type"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 13
                  }
                },
                id: 'farm_land_type',
                label: 'Land type',
                select: true,
                error: !!errors.farm_land_type,
                helperText: errors.farm_land_type?.message
              }}
            >
              {LAND_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>
            <FormSelectField
              name="farm_irrigation"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 15
                  }
                },
                id: 'farm_irrigation',
                label: 'Irrigation',
                select: true,
                error: !!errors.farm_irrigation,
                helperText: errors.farm_irrigation?.message
              }}
            >
              {IRRIGATION_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>

            <FormTextField
              name="farm_region"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 17
                  }
                },
                label: 'Region / State / Province (if applicable)',
                error: !!errors.farm_region,
                helperText: errors.farm_region?.message
              }}
            />

            <FormTextField
              name="farm_street"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 19
                  }
                },
                label: 'Street',
                error: !!errors.farm_street,
                helperText: errors.farm_street?.message
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmInformation;

import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

export type NavigationTabProps = {
  children: React.ReactNode;
  className?: string;
  to: string;
  state?: object;
};

const NavigationTab = ({
  children,
  to,
  className,
  state
}: NavigationTabProps) => {
  return (
    <NavLink
      to={to}
      state={state}
      className={({ isActive }) => {
        return twMerge(
          'flex w-full items-center justify-center p-2 text-sm no-underline',
          isActive && 'rounded-md bg-[#b3cbbd]/50',
          className
        );
      }}
    >
      <div className="flex flex-col items-center gap-1 font-medium leading-normal text-white">
        {children}
      </div>
    </NavLink>
  );
};

export default NavigationTab;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMe } from 'entities/Auth/sdk';
import ContractsChipListProps from 'entities/Contracts/components/ContractsChipListProps';
import ContractsListFilters from 'entities/Contracts/components/ContractsListFilters';
import ContractsListTable from 'entities/Contracts/components/ContractsListTable';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import {
  ContractsListFiltersProps,
  useContractsList
} from 'entities/Contracts/sdk';

import Card from 'components/Card';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

const ContractsList = () => {
  const { setPageTitle } = useLayout();
  const location = useLocation();
  const initialFilters = location.state?.filters || {
    status: CONTRACT_STATUSES.ACTIVE
  };

  const [filters, setFilters] =
    useState<ContractsListFiltersProps>(initialFilters);

  const initialSort = location.state?.sort || {
    sort: 'id',
    direction: 'asc'
  };

  const [sort, setSort] = useState<ListSortProps>(initialSort);

  const { data: me, isLoading: meLoading } = useMe();

  useEffect(() => {
    setPageTitle(<PageTitle text="Contracts"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const {
    data: contracts,
    isLoading: contractsLoading,
    mutate: contractsRefetch
  } = useContractsList({
    filters,
    sort: buildSort(sort)
  });

  return (
    <div className="flex h-full flex-col px-[26px] py-5">
      <Card
        classes={{
          root: 'flex flex-col h-full  gap-5 p-5 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
        }}
      >
        <div className="flex items-center justify-between">
          <ContractsChipListProps filters={filters} onFilter={setFilters} />
          <ContractsListFilters filters={filters} onFilter={setFilters} />
        </div>
        {(contractsLoading || meLoading) && <ContentLoading />}
        {!contractsLoading && contracts && me && (
          <ContractsListTable
            currency={me.currency}
            contracts={contracts}
            contractsRefetch={contractsRefetch}
            filters={filters}
            onSort={setSort}
            sort={sort}
          />
        )}
      </Card>
    </div>
  );
};

export default ContractsList;

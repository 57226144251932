import { generatePath, useNavigate } from 'react-router-dom';

import { ChevronRight, PenIcon, TractorIcon } from 'assets';
import { capitalize } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { OutgrowerDetailResponse } from 'entities/Outgrower/sdk';

import Text from 'components/Text';

import { formatSize } from 'utils/numbers';

import { URLS } from 'config/urls';

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => {
  return (
    <Text color="wet-green" className="font-normal leading-5 tracking-[0.15px]">
      {children}
    </Text>
  );
};

interface ValueProps {
  children: React.ReactNode;
}
const Value = ({ children }: ValueProps) => {
  return (
    <Text
      color="wet-green"
      className="font-semibold leading-6 tracking-[0.15px]"
    >
      {children}
    </Text>
  );
};

interface OutgrowerFarmAndContractProps {
  outgrower: OutgrowerDetailResponse;
}

const OutgrowerFarmAndContracts = ({
  outgrower
}: OutgrowerFarmAndContractProps) => {
  const navigate = useNavigate();

  const farm = outgrower.farms[0];

  return (
    <div className="flex flex-1 flex-col rounded-xl border border-solid border-[#D4DAE1] px-5 py-3">
      <div className="flex w-full items-center justify-between border-0 border-b border-solid border-b-[#D4DAE1] pb-2">
        <div className="flex items-center gap-2">
          <TractorIcon />
          <Text color="wet-green" className="font-semibold leading-[19.2px]">
            Farm
          </Text>
        </div>
        <div
          className="flex cursor-pointer items-center gap-1"
          onClick={() =>
            navigate(
              generatePath(URLS.OUTGROWER_UPDATE, {
                outgrowerId: outgrower.id.toString()
              })
            )
          }
        >
          <PenIcon />
          <Text
            color="wet-green"
            className="text-sm font-medium leading-[18px]"
          >
            Edit Farm
          </Text>
        </div>
      </div>
      <div
        className={twMerge(
          'flex w-full justify-between pb-3 pt-5',
          farm.contracts.length > 0 &&
            'border-0 border-b border-solid border-b-[#D4DAE1]'
        )}
      >
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-1">
            <Label>Name:</Label>
            <Value>{farm.name || '-'}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Number:</Label>
            <Value>{farm.number || '-'}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Farm size:</Label>
            <Value>{formatSize(farm.size)} ha</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Land type:</Label>
            <Value>{capitalize(farm.ownership) || '-'}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Soil type:</Label>
            <Value>{farm.soil_type || '-'}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Irrigation:</Label>
            <Value>
              {farm.irrigation === null ? '-' : farm.irrigation ? 'Yes' : 'No'}
            </Value>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-1">
            <Label>Country:</Label>
            <Value>{farm.country}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Region/ State/ Province:</Label>
            <Value>{farm.region || 'N/A'}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Town:</Label>
            <Value>{farm.town}</Value>
          </div>
          <div className="flex items-center gap-1">
            <Label>Street:</Label>
            <Value>
              {farm.street || farm.street_number
                ? `${farm.street || ''} ${farm.street_number || ''}`
                : 'N/A'}
            </Value>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 pt-3">
        {farm.contracts.map((contract) => (
          <div className="flex w-full justify-between">
            <Label>Contract ({contract.id}):</Label>
            <Value>
              <div
                className="flex cursor-pointer"
                onClick={() =>
                  navigate(
                    generatePath(URLS.OUTGROWER_CONTRACTS, {
                      outgrowerId: outgrower.id.toString()
                    })
                  )
                }
              >
                <div>{contract.crop}</div>
                <ChevronRight />
              </div>
            </Value>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OutgrowerFarmAndContracts;

import { useCallback, useEffect, useState } from 'react';

import {
  CalendarIcon,
  CropIcon,
  CropVarietyIcon,
  InformationPaleGrayIcon,
  LandSizeIcon
} from 'assets';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { filter, isNil } from 'lodash';

import { FormProps } from 'entities/Contracts/components/ContractCreateForm';
import {
  CropChoicesListResponse,
  CropVarietyChoicesListResponse,
  getCropVarietyChoicesList
} from 'entities/Crops/sdk';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import { FormDateField, FormSelectField, FormTextField } from 'components/Form';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch
} from 'utils/forms';
import { formatSize } from 'utils/numbers';

import ConractCropSelect from '../ContractCropSelect';

interface ContractFormProps {
  crops: Array<CropChoicesListResponse>;
  control: Control<FormProps>;
  setValue: UseFormSetValue<FormProps>;
  watch: UseFormWatch<FormProps>;
  errors: FieldErrors<FormProps>;
  farm: OutgrowerFarmResponse;
}

const ContractForm = ({
  crops,
  control,
  setValue,
  watch,
  errors,
  farm
}: ContractFormProps) => {
  const [cropVarieties, setCropVarieties] = useState<
    Array<CropVarietyChoicesListResponse>
  >([]);
  const [showVarieties, setShowVarieties] = useState<boolean>(false);

  const handleCropClick = useCallback(async (cropId: number | null) => {
    setShowVarieties(false);

    if (cropId) {
      const varieties = await getCropVarietyChoicesList(cropId);
      setCropVarieties(varieties);
      setShowVarieties(true);
      return;
    }
  }, []);

  useEffect(() => {
    const [executionStartDate, cropVariety, crop] = watch([
      'execution_start_date',
      'crop_variety',
      'crop'
    ]);

    if (isNil(executionStartDate) || isNil(cropVariety) || isNil(crop)) {
      return;
    }

    const selectedCropVariety = filter(
      cropVarieties,
      (variety) => variety.id === cropVariety
    )[0];

    const endDate = dayjs(executionStartDate).add(
      selectedCropVariety?.duration_in_weeks,
      'weeks'
    );

    setValue('expected_harverst_date', endDate);
  }, [cropVarieties, watch, setValue]);

  const refetchCropVarieties = useCallback(async (cropId: number) => {
    const varieties = await getCropVarietyChoicesList(cropId);
    setCropVarieties(varieties);
    setShowVarieties(true);
  }, []);

  // If we go back to the form via the "Back button", we should see the crops and varieties selected
  useEffect(() => {
    const fetchData = async () => {
      const cropSelected = watch('crop');
      const cropVarietySelected = watch('crop_variety');

      if (
        !isNil(cropSelected) &&
        !isNil(cropVarietySelected) &&
        !showVarieties
      ) {
        await refetchCropVarieties(cropSelected);
      }
    };

    fetchData();
  }, [watch, refetchCropVarieties, showVarieties]);

  const isDateDifferentFromMonday = (date: Dayjs) => {
    const day = date.day();

    return day !== 1;
  };

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <CropIcon />
          <Text className="text-base font-semibold leading-[19.2px]">Crop</Text>
        </div>

        <Text
          color="wet-green"
          className="text-base font-normal leading-[19.2px]"
        >
          Select crop
        </Text>
        <ConractCropSelect
          watch={watch}
          crops={crops}
          control={control}
          setValue={setValue}
          errors={errors}
          onCropClick={handleCropClick}
          className="border-0 p-0 shadow-none"
        />

        {showVarieties && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <CropVarietyIcon />
              <Text className="text-base font-semibold leading-[19.2px]">
                Crop variety
              </Text>
            </div>
            <FormSelectField
              name="crop_variety"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                id: 'crop_variety',
                className: 'w-[244px]',
                label: 'Crop Variety*',
                select: true,
                defaultValue: 'default-variety',
                error: !!errors.crop_variety,
                helperText: errors.crop_variety?.message
              }}
            >
              <MenuItem value="default-variety" disabled>
                Select variety
              </MenuItem>
              {cropVarieties.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </FormSelectField>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <CalendarIcon />
          <Text className="text-base font-semibold leading-[19.2px]">
            Contract duration
          </Text>
        </div>
        <div className="flex gap-4">
          <FormDateField
            name="signature_date"
            control={control}
            rules={{ required: 'This field is required' }}
            datePickerProps={{
              label: 'Signature date'
            }}
            fieldProps={{
              placeholder: '',
              error: !!errors.signature_date,
              helperText: errors.signature_date?.message
            }}
          />
          <FormDateField
            name="execution_start_date"
            control={control}
            rules={{ required: 'This field is required' }}
            datePickerProps={{
              label: (
                <div>
                  Execution date{' '}
                  <Tooltip
                    title={
                      <div>
                        Date when pre-planting activities will start and the
                        first weekly tasks will be sent out
                      </div>
                    }
                  >
                    <InformationPaleGrayIcon />
                  </Tooltip>
                </div>
              ),
              minDate: dayjs().add(1, 'day'),
              shouldDisableDate: isDateDifferentFromMonday
            }}
            fieldProps={{
              placeholder: '',
              error: !!errors.execution_start_date,
              helperText: errors.execution_start_date?.message
            }}
          />
          <FormDateField
            name="expected_harverst_date"
            control={control}
            datePickerProps={{
              slots: {
                openPickerIcon: () => null
              },
              label: (
                <div>
                  Expected harvest date{' '}
                  <Tooltip title={<div>Anticipated harvest completion</div>}>
                    <InformationPaleGrayIcon />
                  </Tooltip>
                </div>
              ),
              disabled: true
            }}
            fieldProps={{
              placeholder: '',
              error: !!errors.expected_harverst_date,
              helperText: errors.expected_harverst_date?.message
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <LandSizeIcon />
          <Text className="text-base font-semibold leading-[19.2px]">
            Land size
          </Text>
        </div>
        <div className="flex items-center gap-4">
          <FormTextField
            name="contract_land_size"
            control={control}
            rules={{
              required: 'This field is required',
              max: {
                value: farm.size,
                message: 'Contract land size cannot be larger than farm size'
              },
              pattern: {
                value: /^\d*\.?\d{0,2}$/, // Allows up to 2 decimal places
                message: 'Only up to two decimal places are allowed'
              }
            }}
            fieldProps={{
              type: 'number',
              label: 'Contract land size (ha)',
              className: 'w-[244px]',
              error: !!errors.contract_land_size,
              helperText: errors.contract_land_size?.message
            }}
          />
          <div className="flex gap-1 text-sm text-wet-green">
            <div>Farm size is</div>
            <div className="font-medium"> {formatSize(farm.size)} ha</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractForm;

import { useForm } from 'react-hook-form';

import {
  contractAnticipatedStageUpdate,
  useContractAnticipatedStageOptions
} from 'entities/Contracts/sdk';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FormSelectField } from 'components/Form';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';

import { SwrMutator } from 'utils/sdk';

export interface FormProps {
  selected_stage: number;
}

export interface ContractAnticipatedStageSelectDialogProps {
  contractId: number;
  onClose: () => void;
  contractDiaryRefetch: SwrMutator;
}

const ContractAnticipatedStageSelectDialog = ({
  contractId,
  onClose,
  contractDiaryRefetch
}: ContractAnticipatedStageSelectDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>();

  const {
    data: anticipatedStageOptions,
    isLoading: anticipatedStageOptionsLoading
  } = useContractAnticipatedStageOptions({
    contractId: contractId.toString()
  });

  const onSubmit = (data: FormProps) => {
    const apiData = {
      stage_id: data['selected_stage']
    };
    return contractAnticipatedStageUpdate({
      contractId: contractId.toString(),
      data: apiData
    }).then(() => {
      onClose();
      contractDiaryRefetch();
    });
  };

  return (
    <Dialog onClose={onClose} title="Edit Stage Next Week">
      <div className="flex flex-col pt-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col gap-5"
        >
          <div className="flex flex-col gap-4">
            <Text
              classes={{
                root: 'tracking-[0.15px] text-wet-green leading-[30px] font-medium text-xl'
              }}
            >
              Please select the next stage from the available options
            </Text>
            <FormSelectField
              name="selected_stage"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                id: 'selected_stage',
                label: 'Stage*',
                select: true,
                defaultValue: 'default-variety',
                error: !!errors.selected_stage,
                helperText: errors.selected_stage?.message
              }}
            >
              <MenuItem value="default-variety" disabled>
                Select stage
              </MenuItem>
              {!anticipatedStageOptionsLoading &&
                anticipatedStageOptions &&
                anticipatedStageOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.stage}
                  </MenuItem>
                ))}
            </FormSelectField>
            <div className="flex justify-center gap-3">
              <Button
                variant="outlined"
                className="font-medium"
                color="wetGreen"
                onClick={onClose}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="font-medium"
                color="wetGreen"
              >
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContractAnticipatedStageSelectDialog;

import {
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput
} from 'react-international-phone';
import 'react-international-phone/style.css';

import InputAdornment from 'components/InputAdornment';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Text from 'components/Text';
import TextField from 'components/TextField';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  label: React.ReactNode;
  placeholder?: string;
  error?: boolean;
  helperText: React.ReactNode;
}

const PhoneInput = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  helperText
}: PhoneInputProps) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      value,
      defaultCountry: 'ke', // 'ke' stands for Kenya
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      }
    });

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      type="tel"
      inputRef={inputRef}
      value={inputValue}
      onChange={handlePhoneValueChange}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginRight: '2px', marginLeft: '-12px' }}
            >
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                sx={{
                  // Update default spacing
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important'
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  svg: {
                    right: 0
                  }
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{ display: 'flex' }} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{ marginRight: '8px' }}
                      />
                      <Text color="wet-green" marginRight="8px">
                        {country.name}
                      </Text>
                      <Text color="gray">+{country.dialCode}</Text>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          )
        }
      }}
    />
  );
};

export default PhoneInput;

import {
  ClearAllInGreenCircle,
  GrassInGreenIcon,
  LineChartInGreeenCircle
} from 'assets';
import { capitalize } from 'lodash';

import { ContractDiaryProps } from 'entities/Contracts/sdk';

import CircularProgress from 'components/CircularProgress';
import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';

interface OutgrowerContractDiaryStatisticsProps {
  contractDiary: ContractDiaryProps;
}

const UpcomingContractDiaryStatistics = ({
  contractDiary
}: OutgrowerContractDiaryStatisticsProps) => {
  return (
    <div className="flex gap-3">
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[280px] flex-[1] px-3 py-[10px]"
        icon={<ClearAllInGreenCircle />}
        title="Status"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {capitalize(contractDiary.status)}
            </Text>
            <Text
              color="wet-green"
              className="flex items-center whitespace-pre text-sm leading-[18px]"
            >
              <div className="font-normal text-wet-green">
                Execution Start:{' '}
              </div>
              <div className="font-semibold text-wet-green">
                {contractDiary.execution_start_date}
              </div>
            </Text>
          </div>
        }
      />
      <StatisticsCard
        cardContentClassName="gap-3"
        className="min-w-[80px] flex-[1] px-3 py-[10px]"
        icon={<GrassInGreenIcon />}
        title="Crop"
        value={
          <div className="flex h-full flex-col gap-[2px]">
            <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-wet-green">
              {contractDiary.crop}
            </Text>
            <Text
              color="wet-green"
              className="text-xs font-semibold uppercase leading-[18px] tracking-[0.15px] text-wet-green"
            >
              {contractDiary.crop_variety}
            </Text>
          </div>
        }
      />

      <StatisticsCard
        cardContentClassName="gap-2"
        className="min-w-[175px] flex-[1] px-3 py-[10px]"
        icon={<LineChartInGreeenCircle />}
        title="Task Progress"
        value={
          <div className="flex h-full items-center gap-2">
            <div className="relative inline-flex">
              <CircularProgress
                variant="determinate"
                thickness={8}
                size={45}
                value={100}
                className="text-fresh-green"
              />
              <CircularProgress
                variant="determinate"
                thickness={8}
                size={45}
                value={contractDiary.task_progress}
                className="absolute left-0 text-wet-green"
                classes={{
                  circle: '[stroke-linecap:round]'
                }}
              />
            </div>
            <div className="flex items-center">
              <Text
                color="wet-green"
                className="text-[28px] font-semibold leading-7 tracking-[0.15px]"
              >
                {contractDiary.task_progress}
              </Text>
              <Text className="text-xl font-semibold leading-5 tracking-[0.15px] text-[#757575]">
                % sent
              </Text>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default UpcomingContractDiaryStatistics;
